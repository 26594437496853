<template>
  <div
    class="vsm-item"
    :class="[{'first-item' : firstItem }, {'mobile-item' : mobileItem}, {'open-item' : show}, {'active-item' : active}, {'parent-active-item' : childActive}]"
    @mouseenter="mouseEnter($event)"
  >
    <template v-if="isRouterLink">
      <router-link
        class="vsm-link"
        :class="[item.class, {'vsmlinkexist' : item.href!='' && item.href!==null}]"
        :to="item.href"
        :disabled="item.disabled"
        :tabindex="item.disabled ? -1 : ''"
        v-bind="item.attributes"
        @click.native="clickEvent"
      >
        <q-menu
          transition-show="scale"
          transition-hide="scale"
          touch-position
          context-menu
        >
          <q-list dense class="topTabContext">
            <q-item clickable v-close-popup v-on:click="addFavoriteTab(item)">
              <!-- 즐겨찾기 추가/삭제 -->
              <q-item-section><q-icon class="text-dark" name="star" /> {{setFavoriteLabel(item)}} </q-item-section>
            </q-item>
            <q-item clickable v-close-popup v-on:click="openNewWindow(item)">
              <q-item-section><q-icon class="text-dark" name="launch" /> {{$language('새창으로 열기')}} </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
        <template v-if="item.icon">
          <i
            v-if="typeof item.icon === 'string' || (item.icon instanceof String)"
            class="vsm-icon material-icons"
            :class="item.icon"
          >{{ item.icon }}</i>
          <component
            :is="item.icon.element ? item.icon.element : 'i'"
            v-else
            class="vsm-icon material-icons"
            :class="item.icon.class"
            v-bind="item.icon.attributes"
          >
            {{ item.icon.text }}
          </component>
        </template>
        <template v-if="!isCollapsed || mobileItem">
          <component
            :is="item.badge.element ? item.badge.element : 'span'"
            v-if="item.badge"
            :style="[rtl ? (item.child ? {'margin-left' : '30px'} : '') : (item.child ? {'margin-right' : '30px'} : '')]"
            class="vsm-badge"
            :class="item.badge.class"
            v-bind="item.badge.attributes"
          >
            {{ item.badge.text }}
          </component>
          <span class="vsm-title">{{ item.title }}</span>
          <div
            v-if="item.child"
            class="vsm-arrow"
            :class="[{'open-arrow' : show}, {'slot-icon' : $slots['dropdown-icon']}]"
          >
            <slot name="dropdown-icon" />
          </div>
        </template>
      </router-link>
    </template>
    <template v-else>
      <a
        class="vsm-link"
        :class="[item.class, {'vsmlinkexist' : item.href!=''}]"
        :href="item.href ? item.href : '#'"
        :disabled="item.disabled"
        :tabindex="item.disabled ? -1 : ''"
        v-bind="item.attributes"
        @click="clickEvent"
      >
        <template v-if="item.icon">
          <i
            v-if="typeof item.icon === 'string' || (item.icon instanceof String)"
            class="vsm-icon material-icons"
            :class="item.icon"
          >{{ item.icon }}</i>
          <component
            :is="item.icon.element ? item.icon.element : 'i'"
            v-else
            class="vsm-icon material-icons"
            :class="item.icon.class"
            v-bind="item.icon.attributes"
          >
            {{ item.icon.text }}
          </component>
        </template>
        <template v-if="!isCollapsed || mobileItem">
          <component
            :is="item.badge.element ? item.badge.element : 'span'"
            v-if="item.badge"
            :style="[rtl ? (item.child ? {'margin-left' : '30px'} : '') : (item.child ? {'margin-right' : '30px'} : '')]"
            class="vsm-badge"
            :class="item.badge.class"
            v-bind="item.badge.attributes"
          >{{ item.badge.text }}</component>
          <span class="vsm-title">{{ item.title }}</span>
          <div
            v-if="item.child"
            class="vsm-arrow"
            :class="[{'open-arrow' : show}, {'slot-icon' : $slots['dropdown-icon']}]"
          >
            <slot name="dropdown-icon" />
          </div>
        </template>
      </a>
    </template>
    <template v-if="item.child">
      <template v-if="!isCollapsed">
        <transition
          name="expand"
          @enter="expandEnter"
          @afterEnter="expandAfterEnter"
          @beforeLeave="expandBeforeLeave"
        >
          <div
            v-if="show"
            class="vsm-dropdown"
          >
            <listItem
              :items="item.child"
              :show-child="showChild"
              :rtl="rtl"
            >
              <slot
                slot="dropdown-icon"
                name="dropdown-icon"
              />
            </listItem>
          </div>
        </transition>
      </template>
    </template>
  </div>
</template>

<script>
import ListItem from './CSidebarListItem.vue'
import { itemMixin, animationMixin } from './js/mixin'
import transactionConfig from '@/js/transactionConfig'

export default {
  components: {
    ListItem
  },
  mixins: [itemMixin, animationMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    firstItem: {
      type: Boolean,
      default: false
    },
    isCollapsed: {
      type: Boolean
    },
    mobileItem: {
      type: Boolean,
      default: false
    },
    activeShow: {
      type: Number,
      default: null
    },
    showChild: {
      type: Boolean,
      default: false
    },
    showOneChild: {
      type: Boolean,
      default: false
    },
    rtl: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    mouseEnter (event) {
      if (this.isCollapsed && this.firstItem && !this.mobileItem && !this.item.disabled) {
        this.$parent.$emit('mouseEnterItem', {
          item: this.item,
          pos:
              event.currentTarget.getBoundingClientRect().top -
              this.$parent.$el.getBoundingClientRect().top,
          height: this.$el.offsetHeight
        })
      }
    },
    setFavoriteLabel(tag) {
      if (this.$store.state.app.favorites.findIndex(i => i.sysMenuId === tag.name) < 0) {
        return this.$language('즐겨찾기 추가');
      } else {
        return this.$language('즐겨찾기 삭제'); 
      }
      
    },
    addFavoriteTab(tag) {
      this.$http.url = transactionConfig.sys.favorite.insert.url;
      if (this.$store.state.app.favorites.findIndex(i => i.sysMenuId === tag.name) < 0) {
        this.$http.type = 'POST';
      } else {
        this.$http.type = 'PUT';
      }
      this.$http.param = {
        userId: this.$store.getters.user.userId,
        sysMenuId: tag.name,
        sortOrder: this.$store.state.app.favorites.length + 1
      };
      this.$http.request(() => {
        this.$store.dispatch('GetFavorites', {userId: this.$store.getters.user.userId});
      });
    },
    openNewWindow(tag) {
      window.open(tag.href);
    }
  }
}
</script>
