<!--
  TODO : 스크롤 영역 처리
-->
<template>
  <div>
    <q-table
      v-table-resizable
      :key="tableKey"
      :tableId="tableId"
      ref="compo-table"
      class="default-table"
      :class="[
        topBorderClass,
        isDashboard ? 'isDashboardTable' : '',
        isDense ? 'default-table-no-padding' : '',
        expanded ? '' : 'fold-table',
      ]"
      :style="{ height: expanded ? height : '50px' }"
      :card-class="cardClass"
      flat
      bordered
      :grid="grid"
      :fullscreen="fullscreen"
      :separator="separator"
      :data="data"
      :columns="arrangColumns"
      :row-key="rowKey"
      :selection="selection"
      :selected.sync="selected"
      :visible-columns="visibleColumns"
      :filter="filter"
      :dense="dense"
      :loading="loading"
      :no-data-label="noDataLabel"
      :no-results-label="noResultLabel"
      :pagination.sync="initialPagination"
      :hide-pagination="false"
      :virtual-scroll="!usePaging"
      :virtual-scroll-slice-size="!usePaging ? 20 : 20"
      :virtual-scroll-item-size="!usePaging ? 48 : 0"
      :virtual-scroll-sticky-size-start="!usePaging ? 48 : 0"
      :hide-bottom="hideBottom"
      :hide-header="hideHeader"
      @selection="changeSelection"
      @virtual-scroll="virtualScroll"
    >
      <!-- ### loading 처리 -->
      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>

      <!-- ### 데이터 없음 / filtering 된 데이터 없음 문구 처리 -->
      <template v-slot:no-data="{ message }">
        <div
          class="full-width row flex-center text-accent q-gutter-sm gridNodataDiv"
        >
          <q-icon size="2em" name="sentiment_dissatisfied" />
          <span>
            {{ message }}
          </span>
        </div>
      </template>

      <!-- 상단 타이틀 / 컬럼 control -->
      <template v-slot:top v-if="isTop">
        <template v-if="isTitle">
          <div class="float-left">
            <!-- 타이틀 앞 표시영역 -->
            <slot name="perfixTitle"></slot>
            <!-- 타이틀 영역 -->
            <div class="q-table__title">
              <!-- <i class="text-primary lnr-list"></i>  -->
              <i class="pe-7s-angle-right-circle"></i>
              {{ convertTitle }}
              <slot name="suffixTitle"></slot>
            </div>
            <!-- 타이틀 뒤 표시영역 -->
          </div>
          <!-- 필터링 영역 -->
          <div
            class="float-left"
            :class="[
              { gridSearchIcon: isTitle },
              { gridSearchIcon2: !isTitle },
            ]"
          >
            <q-input
              v-if="filtering"
              class="filtering-text"
              dense
              debounce="300"
              color="white"
              :style="
                tableWidth > 550
                  ? 'min-width: 150px;max-width: 250px;'
                  : 'min-width: 150px;max-width: 150px;'
              "
              placeholder="Search"
              v-model="filter"
            >
              <template v-slot:append>
                <q-icon name="search"></q-icon>
              </template>
            </q-input>
            <slot name="customFilter"></slot>
          </div>
          <!-- chip 추가 -->
          <div class="table-chip-div">
            <slot name="table-chip"></slot>
          </div>
          <q-space />
          <div class="col-auto cardselectarea">
            <slot name="table-header-append"></slot>
          </div>
          <!-- 버튼 영역 -->
          <div class="q-mt-md gridbtntop">
            <slot name="table-button"></slot>
          </div>
          <div class="float-right">
            <!-- 설명 영역 -->
            <slot name="prefixContent"></slot>
            <q-btn
              v-if="isExcelDown && data.length > 0"
              flat
              dense
              class="tableExcelIcon"
              @click="downloadExcelHtml"
            >
              <q-tooltip anchor="top left" self="center left">
                {{ $language("엑셀 다운로드") }}
              </q-tooltip>
            </q-btn>
            <q-btn
              v-if="isFullScreen"
              flat
              dense
              color="grey-6"
              :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
              class="tabletopicon tablefullscreen"
              @click="clickFullScreen"
            >
              <q-tooltip v-if="fullscreen">
                {{ $language("원래대로") }}
              </q-tooltip>
              <q-tooltip anchor="top left" self="center left" v-else>
                {{ $language("전체화면") }}
              </q-tooltip>
            </q-btn>
            <!-- 컬럼 컨트롤 영역 -->
            <div v-if="columnSetting" class="table-setting gridsettingIcon">
              <q-btn
                flat
                dense
                color="grey-6"
                icon="more_vert"
                class="tabletopicon"
              >
                <q-tooltip>
                  {{ $language("컬럼설정") }}
                </q-tooltip>
                <q-menu transition-show="jump-down" transition-hide="jump-up">
                  <q-list dense class="configColumnSet">
                    <q-item
                      v-for="(column, idx) in columnsControl"
                      :key="idx"
                      tag="label"
                      v-ripple
                      :disable="column.disabled"
                    >
                      <q-item-section side top>
                        <q-checkbox
                          v-if="column.disabled"
                          class="tableCheckBox"
                          :disable="true"
                          color="orange-custom"
                          :value="true"
                        ></q-checkbox>
                        <q-checkbox
                          v-else
                          class="tableCheckBox"
                          color="orange-custom"
                          v-model="column.check"
                          v-on:click.native="colChange(column)"
                        ></q-checkbox>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{
                          $language(column.label)
                        }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </div>
            <q-btn
              v-if="collapsed"
              flat
              dense
              class="card-collapse"
              color="grey-6"
              :icon="expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
              @click="expanded = !expanded"
            >
              <q-tooltip>
                {{ expanded ? "접기" : "펼치기" }}
              </q-tooltip>
            </q-btn>
          </div>
          <slot name="customTableTitleEditor"></slot>
        </template>
      </template>

      <template
        v-slot:header="props"
        v-if="viewHeaders && viewHeaders.length > 0"
      >
        <q-tr v-for="(viewHeader, idx) in viewHeaders" :key="idx" :ref="'ctr'">
          <template v-if="selection !== 'none' && idx === 0 && editable">
            <q-th
              auto-width
              class="text-center"
              :rowspan="colDepth"
              :class="[fixFlag ? 'checkbox-th-fix' : '', 'checkbox-th']"
              style="min-width: 24px; max-width: 24px"
            >
              <q-checkbox
                v-if="selection === 'multiple'"
                class="tableCheckBox"
                color="orange-custom"
                :disable="!editable"
                dense
                v-model="props.selected"
              />
            </q-th>
          </template>
          <q-th
            v-if="isExpand && idx === 0"
            auto-width
            class="text-center"
            :rowspan="colDepth"
          >
            <slot name="expand-header"></slot>
          </q-th>
          <!-- <q-th v-if="isTree && idx===0" auto-width class="text-center" :rowspan="colDepth">
            {{treeHeaderLabel}}
          </q-th> -->
          <slot name="perTh" v-bind="props"></slot>
          <template v-for="col in viewHeader">
            <q-th
              v-if="col.colspan === 1 ? idx + 1 === col.level : true"
              :auto-width="false"
              :key="col.name"
              class="text-center"
              :colspan="col.colspan"
              :rowspan="
                col.colspan === 1 && !col.hasOwnProperty('child')
                  ? colDepth - idx
                  : 1
              "
              :props="
                $_.findIndex(arrangColumns, { name: col.name }) > -1
                  ? props
                  : void 0
              "
              :style="setHeaderStyle(col)"
            >
              <span v-html="$language(col.label)"></span>
              <!-- <q-icon v-if="col.blinking"  color="yellow" class="blinking" rounded/> -->
              <font v-if="col.required" color="red"> *</font>
              <template v-if="col.headerCustom">
                <slot
                  name="customHeader"
                  v-bind:props="props"
                  v-bind:col="col"
                ></slot>
              </template>
              <span v-if="col.type === 'attach' && data && data.length > 0">
                <q-btn-group outline>
                  <q-icon
                    name="help"
                    color="primary"
                    class="custom-btn"
                    style="font-size: 1.4em"
                  >
                    <q-tooltip anchor="bottom left" self="top left">
                      <div class="tooltipCustomTop">
                        {{ $language("업로드 제한사항") }}
                      </div>
                      <div class="tooltipCustom">
                        <div class="q-pl-xs" style="text-align: left">
                          <span class="text-positive">{{
                            $language("업로드 가능 확장자")
                          }}</span>
                          :
                          {{
                            col.hasOwnProperty("uploaderSetting")
                              ? col.uploaderSetting.acceptExt
                              : uploaderSetting.acceptExt
                          }}
                          <br />
                          <span class="text-positive">{{
                            $language("업로드 가능 파일 수")
                          }}</span>
                          :
                          {{
                            col.hasOwnProperty("uploaderSetting")
                              ? col.uploaderSetting.limitCnt
                              : uploaderSetting.limitCnt
                          }}
                          <br />
                          <span class="text-positive">{{
                            $language("허용 파일 크기")
                          }}</span>
                          :
                          {{
                            col.hasOwnProperty("uploaderSetting")
                              ? col.uploaderSetting.limitSize
                              : uploaderSetting.limitSize
                          }}MB
                        </div>
                      </div>
                    </q-tooltip>
                  </q-icon>
                </q-btn-group>
              </span>
              <span
                v-if="
                  col.helpcomment !== '' &&
                  col.helpcomment !== null &&
                  col.helpcomment !== undefined
                "
              >
                <q-btn-group outline>
                  <q-icon
                    name="help"
                    style="font-size: 20px; margin-right: 10px"
                    class="inner-help-btn text-primary"
                  >
                    <q-tooltip anchor="bottom left" self="top left">
                      <div class="tooltipCustomTop">
                        {{ $language("설명") }}
                      </div>
                      <div class="tooltipCustom">
                        <div
                          class="q-pl-xs"
                          style="text-align: left"
                          v-html="col.helpcomment"
                        ></div>
                      </div>
                    </q-tooltip>
                  </q-icon>
                </q-btn-group>
              </span>
              <div
                v-if="col.setHeader && (col.type || col.headerType) && editable"
              >
                <template v-if="col.headerType === 'checkbox'">
                  <q-checkbox
                    class="tableCheckBox"
                    color="orange-custom"
                    :disable="!editable"
                    :true-value="col.trueValue ? col.trueValue : 'Y'"
                    :false-value="col.falseValue ? col.falseValue : 'N'"
                    dense
                    v-model="col.value"
                    @input="(val) => headerDataChange(val, props, col)"
                  />
                </template>
                <template v-if="col.type === 'select'">
                  <!-- select -->
                  <c-select
                    stype="tableHeaderselect"
                    :editable="editable"
                    :codeGroupCd="col.codeGroupCd"
                    :comboItems="col.comboItems"
                    :type="!col.none ? 'allEdit' : ''"
                    :itemText="col.itemText ? col.itemText : 'codeName'"
                    :itemValue="col.itemValue ? col.itemValue : 'code'"
                    @datachange="
                      (val) => headerDataChange(val.value, props, col)
                    "
                  />
                  <!-- v-model="props.row[col.name]" -->
                </template>
                <template v-if="col.type === 'html2'">
                  <country-flag :country="col.lang" class="lanauageFlag" />
                </template>
                <template v-else-if="col.type === 'check'">
                  <q-checkbox
                    dense
                    color="orange-custom"
                    class="tableCheckBox"
                    :disable="!editable"
                    :true-value="col.true ? col.true : 'O'"
                    :false-value="col.false ? col.false : 'X'"
                    v-model="col.value"
                    @input="(val) => headerDataChange(val, props, col)"
                  >
                  </q-checkbox>
                </template>
                <!-- date -->
                <template v-else-if="col.type === 'date'">
                  <c-datepicker
                    dense
                    class="tableDatepicker"
                    :type="col.dateType ? col.dateType : 'date'"
                    :disable="!editable"
                    :minuteStep="col.minuteStep ? col.minuteStep : 1"
                    :start="col.start ? col.start : ''"
                    :end="col.end ? col.end : ''"
                    :range="col.range === true ? true : false"
                    v-model="col.value"
                    @input="(val) => headerDataChange(val, props, col)"
                  ></c-datepicker>
                </template>
                <!-- text -->
                <template v-else-if="col.type === 'text'">
                  <q-input
                    dense
                    v-if="col.type === 'text'"
                    v-model="col.value"
                    class="tableHeaderText"
                    @input="(val) => headerDataChange(val, props, col)"
                  ></q-input>
                </template>
                <template v-else-if="col.type === 'number'">
                  <q-input
                    dense
                    v-if="col.type === 'number'"
                    v-model="col.value"
                    type="number"
                    class="tableHeaderText"
                    @input="(val) => headerDataChange(val, props, col)"
                  ></q-input>
                </template>
                <template v-else-if="col.type === 'user'">
                  <q-btn
                    class="tableinnerBtn tableinnerUserSearch"
                    :class="{
                      'tableinnerUserSearch-left':
                        col.align === 'left' ? true : false,
                      'tableinnerUserSearch-right':
                        col.align === 'right' ? true : false,
                    }"
                    flat
                    color="blue-6"
                    :disable="!editable"
                    icon="search"
                    @click.stop="
                      openUserPop({ userId: '', userName: '' }, col, 'header')
                    "
                    :label="col.value"
                    @input="(val) => headerDataChange(val, props, col)"
                  />
                </template>
                <template v-else-if="col.type === 'dept'">
                  <!-- dept (1) -->
                  <c-dept
                    type="edit"
                    label=""
                    :isFirstValue="false"
                    :disable="!editable"
                    v-model="col.value"
                    @datachange="
                      (deptCd, deptName) =>
                        headerDataChange(deptCd, props, col, deptName)
                    "
                  />
                </template>
                <template v-else-if="col.type === 'custom'">
                  <c-select
                    v-if="col.headType === 'select'"
                    stype="tableHeaderselect"
                    :editable="editable"
                    :comboItems="col.comboItems"
                    :type="!col.none ? 'allEdit' : ''"
                    :itemText="col.itemText ? col.itemText : 'codeName'"
                    :itemValue="col.itemValue ? col.itemValue : 'code'"
                    @datachange="
                      (val) => headerDataChange(val.value, props, col)
                    "
                  />
                  <q-btn
                    v-if="col.headType === 'link'"
                    class="tableinnerBtn"
                    :class="{
                      'tableinnerBtn-left': col.align === 'left' ? true : false,
                      'tableinnerBtn-right':
                        col.align === 'right' ? true : false,
                    }"
                    flat
                    :align="col.align"
                    :size="col.size"
                    :color="col.color ? col.color : 'blue-6'"
                    :icon="col.icon ? col.icon : 'build'"
                    @click.stop="headLinkClick(props, col)"
                  >
                  </q-btn>
                  <!-- :icon="col.dayType === 'weekend' ? null : 'build'" -->
                  <q-input
                    dense
                    v-if="col.headType === 'text'"
                    v-model="col.value"
                    type="text"
                    class="tableHeaderText"
                    @input="(val) => headerDataChange(val, props, col)"
                  >
                  </q-input>
                  <c-multi-field
                    v-if="col.headType === 'multiUser'"
                    class="tableHeaderMultiUser"
                    :userInfo.sync="userInfo"
                    isArray
                    type="user"
                    label=""
                    @input="(val) => headerDataChange(val, props, col)"
                  />
                </template>
              </div>
            </q-th>
          </template>
          <slot name="sufTh" v-bind="props"></slot>
        </q-tr>
      </template>

      <!-- 데이터 영역 -->
      <template v-slot:body="props">
        <slot name="description-front-tr" v-bind="props"></slot>
        <template v-if="!customDataTr">
          <q-tr
            :props="props"
            ref="compo-tr"
            v-on:click="(e) => rowClick(e, props.row, props.rowIndex)"
            :class="[
              {
                'bg-light-blue-1': selectedRow(props),
                'table-tr-highLight': setHighLight(props),
                'no-hover-row': noHoverRow,
              },
              customTrClass(props),
            ]"
          >
            <!--
              앞에 배치됨
              columns에 정의한 col이 아닌 custom을 위한 영역
            -->
            <!-- props.row['editable'] -->
            <q-td
              v-if="
                selection !== 'none' &&
                editable &&
                isCreate(props.row, -1, props.rowIndex)
              "
              auto-width
              class="text-center"
              :rowspan="getRowspan(-1, props.rowIndex)"
              :class="fixFlag ? 'checkbox-td' : ''"
              style="min-width: 24px; max-width: 24px"
            >
              <q-checkbox
                class="tableCheckBox"
                color="orange-custom"
                :disable="!editable || props.row[checkDisableColumn]"
                dense
                v-model="props.selected"
              />
            </q-td>
            <q-td v-if="isExpand" auto-width class="text-center">
              <q-btn
                size="xs"
                class="tableInnerIsExpend"
                color="accent"
                round
                dense
                @click="props.expand = !props.expand"
                :icon="props.expand ? 'remove' : 'add'"
              ></q-btn>
            </q-td>
            <slot name="perTd" v-bind="props"></slot>
            <template v-for="(col, colIndex) in props.cols">
              <!-- <q-td v-for="col in props.cols" :key="col.name" :props="props"> -->
              <!-- , setColColor(col), -->
              <q-td
                v-if="isCreate(props.row, colIndex, props.rowIndex)"
                :ref="'custom-td-' + props.rowIndex + '-' + colIndex"
                :auto-width="false"
                :key="col.name"
                :props="props"
                :class="[
                  checkEnable(col, props.row, props)
                    ? ''
                    : `edittd-${col.type}`,
                  col.ellipsis == true ? 'tableinnerBtn-ellipsis' : '',
                ]"
                :style="tdStyle(col)"
                :rowspan="getRowspan(colIndex, props.rowIndex)"
              >
                <!-- @click="tdClick(props, col, $event)"
                @contextmenu.capture.prevent="tdRightClick(props, col)" -->
                <!-- :class 에 포함되었던 속성으로 text box가 앞으로 나옴으로 주석처리
                col.type === 'user' && !checkUserTd(col, props) ? 'edittd-text' : ''  -->
                <template v-if="contentsField(props, col)">
                  <!-- default 영역
                    해당 if는 제일 앞에 두고 그리드를 그리는데 좀 더 빠르게 표시하는게 목적
                  -->
                  <span
                    v-if="check(col.type)"
                    :class="[
                      checkEnable(col, props.row, props) ? 'non-edit' : '',
                      col.innerBtn ? 'descript-span' : '',
                      col.color ? 'text-' + col.color : '',
                    ]"
                  >
                    {{ props.row[col.name] }}
                    <div v-if="col.description" class="description-td">
                      {{ props.row[col.description] }}
                    </div>
                    <div v-if="col.innerBtn">
                      <q-btn-group outline class="ColumInnerBtnGroup">
                        <template v-for="(btn, idx) in col.btns">
                          <q-btn
                            v-if="
                              editable &&
                              !checkEnable(col, props.row, props) &&
                              !checkInnerBtnEnable(btn, props.row, props)
                            "
                            :key="idx"
                            :label="btn.label"
                            :icon="btn.icon ? btn.icon : void 0"
                            :color="btn.color ? btn.color : 'blue-grey-4'"
                            :text-color="
                              btn.textColor ? btn.textColor : 'white'
                            "
                            class="ColumInnerBtn"
                            align="center"
                            @click.stop="innerBtnClicked(col, props, btn)"
                          >
                            <q-tooltip v-if="btn.tooltip">
                              <span v-html="$language(btn.tooltip)" />
                            </q-tooltip>
                          </q-btn>
                        </template>
                      </q-btn-group>
                    </div>
                  </span>
                  <template v-if="editTdCheck(col.type)">
                    <!-- input text -->
                    <template v-if="col.type === 'text'">
                      <c-text-column
                        :editable="editable"
                        :disabled="
                          props.row[checkDisableColumn] ||
                          checkEnable(col, props.row, props)
                        "
                        :col="col"
                        :props="props"
                        :colorClass="checkColorCloass(col, props.row)"
                        @datachange="datachange(props, col)"
                      />
                    </template>
                    <template v-else-if="col.type === 'textarea'">
                      <!-- input textarea -->
                      <c-textarea-column
                        :editable="editable"
                        :disabled="
                          props.row[checkDisableColumn] ||
                          checkEnable(col, props.row, props)
                        "
                        :col="col"
                        :props="props"
                        @datachange="datachange(props, col)"
                      />
                    </template>
                    <template v-else-if="col.type === 'select'">
                      <!-- select -->
                      <c-select
                        stype="tableselect"
                        :editable="editable"
                        :disabled="
                          !editable ||
                          props.row[checkDisableColumn] ||
                          checkEnable(col, props.row, props)
                        "
                        :isSelect="
                          col.isSelect === true || col.isSelect === false
                            ? col.isSelect
                            : true
                        "
                        :isChip="col.isChip"
                        :comboItems="col.comboItems"
                        :codeGroupCd="col.codeGroupCd"
                        :stepperGrpCd="col.stepperGrpCd"
                        :type="!col.none ? 'edit' : ''"
                        :itemText="col.itemText ? col.itemText : 'codeName'"
                        :itemValue="col.itemValue ? col.itemValue : 'code'"
                        v-model="props.row[col.name]"
                        @datachange="(val) => changeSelect(val, props, col)"
                      />
                    </template>
                    <!-- date -->
                    <template v-else-if="col.type === 'date'">
                      <c-datepicker
                        dense
                        class="tableDatepicker"
                        :type="col.dateType ? col.dateType : 'date'"
                        :disabled="
                          checkEnable(col, props.row, props) ||
                          !editable ||
                          props.row[checkDisableColumn]
                        "
                        :range="col.range === true ? true : false"
                        :minuteStep="col.minuteStep ? col.minuteStep : 1"
                        :start="col.start ? col.start : ''"
                        :end="col.end ? col.end : ''"
                        v-model="props.row[col.name]"
                        @datachange="(val) => datachange(props, col)"
                      ></c-datepicker>
                    </template>
                    <template v-else-if="col.type === 'plant'">
                      <!-- plant -->
                      <c-plant
                        :isTable="true"
                        stype="tableselect"
                        :editable="editable"
                        label=""
                        type="none"
                        v-model="props.row[col.name]"
                        @datachange="(val) => datachange(props, col)"
                      />
                    </template>
                    <template v-else-if="col.type === 'plantep'">
                      <!-- plant -->
                      <c-plant
                        :isTable="true"
                        stype="tableselect"
                        :editable="editable"
                        label=""
                        type="none"
                        :isEnterprise="true"
                        v-model="props.row[col.name]"
                        @datachange="(val) => datachange(props, col)"
                      />
                    </template>
                    <template v-else-if="col.type === 'number'">
                      <!-- input number -->
                      <c-number-column
                        :editable="editable"
                        :disabled="
                          props.row[checkDisableColumn] ||
                          checkEnable(col, props.row, props)
                        "
                        :maxlength="col.maxlength > 0 ? col.maxlength : void 0"
                        :col="col"
                        :props="props"
                        :colorClass="checkColorCloass(col, props.row)"
                        v-model="props.row[col.name]"
                        @datachange="datachange(props, col)"
                      />
                    </template>
                    <!-- check box (1) -->
                    <template v-else-if="col.type === 'check'">
                      <q-checkbox
                        dense
                        :color="col.color ? col.color : 'orange-custom'"
                        class="tableCheckBox"
                        :disable="
                          checkEnable(col, props.row, props) || !editable
                        "
                        :true-value="col.true ? col.true : 'O'"
                        :false-value="col.false ? col.false : 'X'"
                        :checked-icon="col.checkIcon ? col.checkIcon : void 0"
                        :unchecked-icon="
                          col.uncheckIcon ? col.uncheckIcon : void 0
                        "
                        v-model="props.row[col.name]"
                        @input="(val) => datachange(props, col)"
                      >
                      </q-checkbox>
                    </template>
                    <template v-else-if="col.type === 'radio'">
                      <c-radio
                        :disabled="
                          checkEnable(col, props.row, props) || !editable
                        "
                        :comboItems="col.comboItems"
                        :codeGroupCd="col.codeGroupCd ? col.codeGroupCd : ''"
                        :itemText="col.itemText ? col.itemText : 'codeName'"
                        :itemValue="col.itemValue ? col.itemValue : 'code'"
                        label=""
                        v-model="props.row[col.name]"
                        @datachange="datachange(props, col)"
                      >
                      </c-radio>
                    </template>
                    <template v-else-if="col.type === 'dept'">
                      <!-- dept (1) -->
                      <c-dept
                        v-if="editable"
                        type="edit"
                        label=""
                        :isFirstValue="col.isFirstValue"
                        :disabled="
                          !editable ||
                          props.row[checkDisableColumn] ||
                          checkEnable(col, props.row, props)
                        "
                        v-model="props.row[col.deptCd]"
                        @setDeptName="(val) => (props.row[col.name] = val)"
                        @datachange="datachange(props, col)"
                      />
                      <span v-else>
                        {{ props.row[col.name] }}
                      </span>
                    </template>
                    <template v-else-if="col.type === 'deptMulti'">
                      <!-- dept multi -->
                      <c-dept-multi
                        v-if="editable"
                        type="edit"
                        label=""
                        :disabled="
                          !editable ||
                          props.row[checkDisableColumn] ||
                          checkEnable(col, props.row, props)
                        "
                        v-model="props.row[col.deptCd]"
                        @setDeptName="(val) => (props.row[col.name] = val)"
                        @datachange="datachange(props, col)"
                      />
                      <span v-else>
                        {{ props.row[col.name] }}
                      </span>
                    </template>
                    <!-- 사용자 검색 -->
                    <template v-else-if="col.type === 'user'">
                      <q-btn
                        v-if="checkUserTd(col, props)"
                        class="tableinnerBtn tableinnerUserSearch"
                        :class="{
                          'tableinnerUserSearch-left':
                            col.align === 'left' ? true : false,
                          'tableinnerUserSearch-right':
                            col.align === 'right' ? true : false,
                        }"
                        flat
                        color="blue-6"
                        :disable="
                          checkEnable(col, props.row, props) ||
                          !editable ||
                          props.row[checkDisableColumn]
                        "
                        :icon="
                          checkEnable(col, props.row, props)
                            ? 'search_off'
                            : 'search'
                        "
                        :label="props.row[col.name]"
                        @click.stop="openUserPop(props.row, col, 'td')"
                      />
                      <template v-else>
                        <c-text-column
                          :editable="editable"
                          :disabled="
                            props.row[checkDisableColumn] ||
                            checkEnable(col, props.row, props)
                          "
                          :col="col"
                          :props="props"
                          @datachange="datachange(props, col)"
                        />
                      </template>
                    </template>
                    <c-vendor
                      v-else-if="col.type === 'vendor'"
                      :disabled="
                        !editable ||
                        props.row[checkDisableColumn] ||
                        checkEnable(col, props.row, props)
                      "
                      type="edit"
                      label=""
                      v-model="props.row[col.name]"
                      @dataChange="
                        (val, oldVal, vendor) =>
                          vendorChange(props, col, vendor)
                      "
                    />
                    <c-facility
                      v-else-if="col.type === 'facility'"
                      :disabled="
                        !editable ||
                        props.row[checkDisableColumn] ||
                        checkEnable(col, props.row, props)
                      "
                      :detail="false"
                      type="edit"
                      label=""
                      v-model="props.row[col.name]"
                      @dataChange="
                        (facility) => facilityChange(props, col, facility)
                      "
                    />
                    <c-equip
                      v-else-if="col.type === 'equip'"
                      :disabled="
                        !editable ||
                        props.row[checkDisableColumn] ||
                        checkEnable(col, props.row, props)
                      "
                      type="edit"
                      label=""
                      name="equipmentCd"
                      v-model="props.row[col.name]"
                      @dataChange="(equip) => equipChange(props, col, equip)"
                    >
                    </c-equip>
                    <!-- attach -->
                    <template v-else-if="col.type === 'attach'">
                      <c-upload-column
                        dense
                        class="tableUploadColumn"
                        :editable="
                          !checkEnable(col, props.row, props) &&
                          editable &&
                          !props.row[checkDisableColumn]
                        "
                        :imageRestriction="col.imageRestriction"
                        :col="col"
                        :row.sync="props.row"
                        :uploaderSetting.sync="uploaderSetting"
                        :colUploaderSetting.sync="col.uploaderSetting"
                        :change.sync="change"
                        @upload-change="
                          (data) => {
                            uploadChange(data, props, col);
                          }
                        "
                      ></c-upload-column>
                    </template>
                    <template v-else-if="col.type === 'file'">
                      <c-file-column
                        :editable="
                          !checkEnable(col, props.row, props) &&
                          editable &&
                          !props.row[checkDisableColumn]
                        "
                        :fileIds.sync="props.row[col.name]"
                        :rowIndex.sync="props.rowIndex"
                        :preview.sync="preview"
                      ></c-file-column>
                    </template>
                    <!-- datetime -->
                    <template v-else-if="col.type === 'datetime'">
                      <c-datepicker
                        dense
                        class="tableDatepicker"
                        :type="col.datetimeType ? col.datetimeType : 'time'"
                        :timePickerOptions="setTimePickerOptions(props, col)"
                        :range="col.range === true ? true : false"
                        :disabled="
                          checkEnable(col, props.row, props) ||
                          !editable ||
                          props.row[checkDisableColumn]
                        "
                        :minuteStep="col.minuteStep ? col.minuteStep : 1"
                        v-model="props.row[col.name]"
                        @datachange="(val) => datachange(props, col)"
                      ></c-datepicker>
                    </template>
                    <c-multi-select
                      v-else-if="col.type === 'multiSelect'"
                      stype="tableselect"
                      :editable="editable"
                      :disabled="checkEnable(col, props.row, props)"
                      :isArray="col.isArray === false ? false : true"
                      :isObject="col.isObject"
                      :codeGroupCd="col.codeGroupCd ? col.codeGroupCd : ''"
                      :stepperGrpCd="col.stepperGrpCd ? col.stepperGrpCd : ''"
                      :valueText="col.valueText ? col.valueText : 'codeName'"
                      :valueKey="col.valueKey ? col.valueKey : 'code'"
                      :comboItems="col.comboItems"
                      :itemText="col.itemText ? col.itemText : 'codeName'"
                      :itemValue="col.itemValue ? col.itemValue : 'code'"
                      v-model="props.row[col.name]"
                      @datachange="(val) => datachange(props, col)"
                    >
                    </c-multi-select>
                  </template>
                  <template v-else>
                    <!-- text 클릭 -->
                    <template v-if="col.type === 'link'">
                      <q-btn
                        v-if="props.row[col.name]"
                        class="tableinnerBtn"
                        :class="{
                          'tableinnerBtn-left':
                            col.align === 'left' ? true : false,
                          'tableinnerBtn-right':
                            col.align === 'right' ? true : false,
                          'tableinnerBtn-ellipsis':
                            col.ellipsis == true ? true : false,
                        }"
                        flat
                        :align="col.align"
                        color="blue-6"
                        :label="props.row[col.name]"
                        @click.stop="linkClick(props, col)"
                      />
                    </template>
                    <!-- tag -->
                    <template v-else-if="col.type === 'tag'">
                      <q-chip
                        v-if="props.row[col.name]"
                        outline
                        square
                        :color="setTagColor(col, props.row[col.name])"
                        :text-color="col.colorItems ? 'white' : 'black'"
                      >
                        {{ setTagName(col, props.row[col.name]) }}
                      </q-chip>
                      <template v-else> </template>
                    </template>
                    <!-- custom -->
                    <template v-else-if="col.type === 'custom'">
                      <slot
                        name="customArea"
                        v-bind:props="props"
                        v-bind:col="col"
                      ></slot>
                    </template>
                    <!-- 천단위 콤마 -->
                    <span v-else-if="col.type === 'cost'">
                      {{ col.prefix ? col.prefix : ""
                      }}{{ props.row[col.name] | toThousandFilter
                      }}{{ col.suffix ? col.suffix : "" }}
                    </span>
                    <!-- O 처리 효율 문제로 솔루션에는 반영하지 않음 -->
                    <!-- <span v-else-if="col.type==='circle'">
                      {{ props.row[col.name] == 'Y' ? 'O' : '' }}
                    </span> -->
                    <!-- HTML태그허용 -->
                    <span
                      v-else-if="col.type === 'html'"
                      v-html="$comm.convertEnter(props.row[col.name])"
                    >
                    </span>
                    <span
                      v-else-if="col.type === 'html2'"
                      v-html="$comm.convertEnter(props.row[col.name])"
                    >
                    </span>
                    <!-- 백그라운드color -->
                    <div
                      v-else-if="col.type === 'color'"
                      :class="
                        props.row[col.name] === 'Y'
                          ? 'tdBackGroundColor'
                          : 'tdBackGroundColorNone'
                      "
                    >
                      {{ props.row[col.name] }}
                    </div>
                    <!-- badge -->
                    <span v-else-if="col.type === 'badge'">
                      <q-badge
                        :color="col.color"
                        :label="props.row[col.name]"
                      />
                    </span>
                    <span v-else-if="col.type === 'img'">
                      <!-- 샘플 -->
                      <img
                        :src="props.row[col.name]"
                        style="width: 150px"
                        :class="{
                          'cursor-pointer': Boolean(props.row.sysAttachFileId),
                        }"
                        @click.prevent="setPreview(props.row)"
                      />
                    </span>
                    <!-- popup proxy -->
                    <q-btn
                      v-else-if="col.type === 'proxy'"
                      class="tableinnerBtn"
                      flat
                      :align="col.align"
                      color="blue-6"
                      :label="
                        props.row[col.name] ? props.row[col.name] : '보기'
                      "
                    >
                      <q-popup-proxy
                        :ref="'proxy_' + props.rowIndex + '_' + col.name"
                        :breakpoint="1000"
                      >
                        <component
                          :is="col.component"
                          :props="props"
                          :col="col"
                          :editable="editable"
                          @callback="
                            (data, color) =>
                              callbackProxy(data, color, props, col)
                          "
                        />
                      </q-popup-proxy>
                    </q-btn>
                  </template>
                </template>
              </q-td>
            </template>
            <!--
              뒤에 배치됨
              columns에 정의한 col이 아닌 custom을 위한 영역
            -->
            <slot name="sufTd" v-bind="props"></slot>
          </q-tr>
        </template>
        <template v-else>
          <slot name="customDataTr" v-bind="props"></slot>
        </template>
        <q-tr
          v-if="showDescription"
          :props="props"
          :key="`e_${props.rowIndex}`"
          class="q-virtual-scroll--with-prev description-tr"
        >
          <q-td colspan="100%" class="description-td">
            <slot name="description-td" v-bind="props"></slot>
          </q-td>
        </q-tr>
        <q-tr v-if="props.expand" :props="props">
          <q-td colspan="100%">
            <slot name="expand-td" v-bind="props"> </slot>
          </q-td>
        </q-tr>
      </template>

      <template v-slot:bottom-row>
        <slot name="customBottomRow"></slot>
      </template>
      <!-- 바텀 영역 -->
      <template v-slot:bottom>
        {{ $language("총 건수") }} :
        {{ data ? data.length : 0 | toThousandFilter }}
        <q-space />

        <!-- 페이징 영역 -->
        <template v-if="usePaging">
          <!-- 페이지 input 영역 -->
          <q-select
            outlined
            dense
            style="min-width: 50px; max-width: 70px"
            :options="pageItems"
            color="table-paging-color"
            class="tablePagingInput"
            v-model="pagePerRow.pageNumber"
            @input="changePageNumber"
          />
          <!-- 페이지네이션 영역 -->
          <q-pagination
            v-model="initialPagination.page"
            :max="pagesNumber"
            :max-pages="5"
            class="tablePagingBtn"
            direction-links
            boundary-links
            icon-first="skip_previous"
            icon-last="skip_next"
            color="table-paging-color"
          />
        </template>
      </template>
    </q-table>
    <c-dialog :param="popupOptions"></c-dialog>

    <!-- 이미지 미리보기 -->
    <q-dialog v-if="preview && preview.src" v-model="preview.isShow">
      <q-card class="preview-image-card">
        <q-btn
          color="white"
          text-color="primary"
          @click="preview.isShow = false"
          round
          dense
          icon="close"
          class="preview-close-btn"
        >
          <!-- 창닫기 -->
          <q-tooltip class="bg-white text-primary">창닫기</q-tooltip>
        </q-btn>
        <q-img :src="preview.src"></q-img>
        <q-card-section>
          <q-btn
            fab
            color="primary"
            icon="save_alt"
            class="absolute"
            style="top: 0; right: 12px; transform: translateY(-50%)"
            @click.prevent="fileDown(preview)"
          ></q-btn>
          <div class="row no-wrap items-center">
            <div class="col text-h6">
              {{ preview.oriFileNm }}
            </div>
            <div
              class="col-auto text-grey text-caption q-pt-md row no-wrap items-center"
            >
              {{ $comm.bytesToSize(preview.fileSize) }}
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { toThousandFilter } from "@/utils/filter";
import { uid } from "quasar";
// import XLSX from 'xlsx'
import selectConfig from "@/js/selectConfig";
import mixinCommon from "./js/mixin-common";
import CountryFlag from "vue-country-flag";
export default {
  /* attributes: name, components, props, data */
  components: { CountryFlag },
  name: "c-table",
  mixins: [mixinCommon],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    expendcolumns: {
      type: Array,
      default: () => [],
    },
    merge: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: true,
    },
    separator: {
      type: String,
      default: "cell", // horizontal, vertical, cell, none
    },
    selection: {
      type: String,
      default: "none", // multiple, single, none
    },
    rowKey: {
      type: String,
      default: "name",
    },
    columnSetting: {
      type: Boolean,
      default: true,
    },
    isFullScreen: {
      type: Boolean,
      default: true,
    },
    isExcelDown: {
      type: Boolean,
      default: true,
    },
    filtering: {
      type: Boolean,
      default: true,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    defaultExpand: {
      type: Boolean,
      default: true,
    },
    cardClass: {
      type: String,
      default: "", // bg-primary text-white
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    autoFullHeight: {
      type: Boolean,
      default: true,
    },
    gridHeight: {
      type: String,
      default: "",
    },
    gridHeightAuto: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: Boolean,
      default: false,
    },
    usePaging: {
      type: Boolean,
      default: true,
    },
    isDashboard: {
      type: Boolean,
      default: false,
    },
    hideBottom: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    isExpand: {
      type: Boolean,
      default: false,
    },
    isTitle: {
      type: Boolean,
      default: true,
    },
    isTop: {
      type: Boolean,
      default: true,
    },
    tableId: {
      type: String,
      default: "",
    },
    isTree: {
      type: Boolean,
      default: false,
    },
    treeHeaderLabel: {
      type: String,
      default: "",
    },
    /* eslint-disable no-unused-vars */
    isSelection: {
      type: Function,
      default: function (props) {
        return true;
      },
    },
    highLightInfo: {
      type: Object,
    },
    topBorderClass: {
      type: String,
      default: "",
    },
    contentsField: {
      type: Function,
      default: function (props, col) {
        return true;
      },
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
    noHighLight: {
      type: Boolean,
      default: false,
    },
    noHoverRow: {
      type: Boolean,
      default: false,
    },
    checkDisableColumn: {
      type: String,
      default: "disable",
    },
    checkClickFlag: {
      type: Boolean,
      default: true,
    },
    tableTimePickerOptions: {
      type: Object,
    },
    customTrClass: {
      type: Function,
      default: function (props) {
        return "";
      },
    },
    pagePerRow: {
      type: Object,
      default: function () {
        return {
          pageNumber: 20,
        };
      },
    },
    changeData: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    customDataTr: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    isDense: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      fullscreen: false,
      tableWidth: 0,
      height: "100%",
      selected: [],
      filter: "",
      colDepth: 1,
      childLength: [],
      viewHeaders: [],
      arrangColumns: [],
      columnsControl: [],
      visibleColumns: [],
      hiddenColumns: [],
      initialPagination: null,
      pageItems: [5, 20, 30, 50, 100, 200],
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        width: "90%",
        top: "10px",
        param: {},
        closeCallback: null,
      },
      setUserInfo: null,
      delay: 180,
      clicks: 0,
      timer: null,
      selectedRowIndex: -1,
      render: {
        from: 0,
        to: 0,
      },
      uploaderSetting: {
        multipleSelFlag: "N",
        previewFlag: "N",
        acceptExt: "*",
        limitSize: 1048576,
        limitCnt: 5,
        noThumbnails: false,
        explainFlag: "N",
        resizeWidth: 0,
        resizeQuality: 1,
      },
      preview: {
        isShow: false,
        sysAttachFileId: "",
        src: "",
        oriFileNm: "",
        fileSize: 0,
        fileExt: "",
      },
      previewUrl: "",
      fileDownUrl: "",
      change: {
        data: "",
      },
      uploadResearchObj: null,
      expanded: true,
      tableKey: "1",
    };
  },
  computed: {
    pagesNumber() {
      let _length = this.data.length;
      if (this.filter) {
        _length = this.$refs["compo-table"].filteredSortedRowsNumber;
      }
      return Math.ceil(_length / this.initialPagination.rowsPerPage);
    },
    isEditing() {
      let val = false;
      let whitList = [
        "html",
        "html2",
        "cost",
        "cost2",
        "link",
        "tag",
        "custom",
        "proxy",
        "img",
        "color",
        "badge",
      ];
      if (this.arrangColumns && this.arrangColumns.length > 0) {
        this.$_.forEach(this.arrangColumns, (col) => {
          if (col.type && this.$_.indexOf(whitList, col.type) === -1) {
            val = true;
            return false;
          }
        });
      }
      return val;
    },
    fixFlag() {
      return this.arrangColumns && this.arrangColumns.length > 0
        ? this.arrangColumns[0].fix
        : false;
    },
    noDataLabel() {
      return this.$language("데이터가 없습니다."); // 데이터가 없습니다.
    },
    noResultLabel() {
      return this.$language("필터링 된 데이터가 없습니다."); // 필터링 된 데이터가 없습니다.
    },
    userInfo() {
      return {
        userItems: [],
        userText: "userName",
        userValue: "userId",
      };
    },
  },
  watch: {
    gridHeight() {
      this.setSize();
    },
    columns: {
      handler: function (c, p) {
        this.setColumnControl();
      },
      deep: true,
    },
    changeData: {
      handler: function (c, p) {
        /**
         * data가 바뀜으로 인해 td안에 있는 컴포넌트가 반응을 해야함에도
         * 반응하지 않는 현상 있음(옛날 정보를 그대로 가지고 있는 경우)
         *
         * 해당 경우로 인해 컴포넌트에 flag를 넘김
         *
         * 컴포넌트 :: attach
         */
        this.change.data = uid();
      },
      deep: false,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    window.addEventListener("resize", this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestory() {
    window.removeEventListener("resize", this.setSize);
  },
  /* methods */
  methods: {
    init() {
      this.previewUrl = selectConfig.com.upload.preview.url;
      this.fileDownUrl = selectConfig.com.upload.fileDown.url;

      // 테이블 접기/펼치기 기본값 셋팅
      if (this.collapsed) {
        this.$set(this.$data, "expanded", this.defaultExpand);
      }
      this.setPaging();
      this.setColumnControl();
      this.setSize();
      this.tableWidth = this.$refs["compo-table"].$el.clientWidth;
    },
    setPaging() {
      if (this.usePaging) {
        this.initialPagination = {
          sortBy: "desc",
          descending: false,
          page: 1,
          rowsPerPage:
            this.pagePerRow && this.pagePerRow.pageNumber
              ? Number(this.$_.clone(this.pagePerRow.pageNumber))
              : 20,
        };
      } else {
        this.initialPagination = {
          sortBy: "desc",
          descending: false,
          page: 1,
          rowsPerPage: 0,
        };
      }
    },
    setColumnControl() {
      this.arrangColumns = [];
      let colDepth = 1;
      this.colDepth = 1;
      this.$_.forEach(this.columns, (column) => {
        let idx = this.$_.findIndex(this.childLength, { level: 1 });
        if (idx > -1) {
          this.childLength[idx].val = 0;
        } else {
          this.childLength.push({ level: 1, val: 0 });
        }
        column.level = 1;
        if (!column.hasOwnProperty("child")) {
          this.arrangColumns.push(column);
          column.colspan = 1;
        } else {
          this.arrangColumns.push.apply(
            this.arrangColumns,
            this.FloorColumn(column, 2, false)
          );
          column.colspan =
            column.child.length +
            this.$_.find(this.childLength, { level: 1 }).val;

          if (this.colDepth > colDepth) {
            colDepth = this.$_.clone(this.colDepth);
          }
          this.colDepth = 1;
        }
      });

      this.colDepth = colDepth;
      if (this.viewHeaders && this.viewHeaders.length > 0) {
        this.viewHeaders = [];
      }

      (this.viewHeaders = []).length = this.colDepth;
      this.viewHeaders.fill(new Array());

      for (let i = 0; i < this.colDepth; i++) {
        this.viewHeaders[i] = this.getLevelHeaders(this.columns, i + 1);
      }

      this.visibleColumns = this.$_.map(this.arrangColumns, "name");
      // 컬럼visible정보를 localStorage에 있으면 불러오기..
      let tablevisiblecol = JSON.parse(window.localStorage.getItem(this.title));
      if (tablevisiblecol !== null) {
        this.visibleColumns = tablevisiblecol;
      }
      let index = 0;
      this.columnsControl = [];
      this.$_.forEach(this.arrangColumns, (col) => {
        this.columnsControl.push({
          label: col.label,
          name: col.name,
          check: this.visibleColumns.indexOf(col.name) > -1 ? true : false,
          disabled:
            this.$_.findIndex(this.merge, { index: index }) > -1 ||
            col.level > 1,
          // disabled: false,
        });
        index++;
      });
      this.$refs["compo-table"].$el.style.setProperty(
        "--my-var",
        this.colDepth * 33 + "px"
      );
    },
    FloorColumn(column, level, checkDept) {
      if (!checkDept) {
        this.colDepth++;
      }
      let returnCols = [];
      let idx = this.$_.findIndex(this.childLength, { level: level });
      if (idx > -1) {
        this.childLength[idx].val = 0;
      } else {
        this.childLength.push({ level: level, val: 0 });
      }
      this.$_.forEach(column.child, (childColumn) => {
        childColumn.level = level;
        if (!childColumn.hasOwnProperty("child")) {
          returnCols.push(childColumn);
          childColumn.colspan = 1;
        } else {
          let _checkDept = level >= this.colDepth ? false : true;
          returnCols.push.apply(
            returnCols,
            this.FloorColumn(childColumn, level + 1, _checkDept)
          );
          childColumn.colspan =
            childColumn.child.length +
            this.$_.find(this.childLength, { level: level }).val;
          this.$_.forEach(this.childLength, (item) => {
            if (level > item.level) {
              item.val += childColumn.child.length - 1;
            }
          });
        }
      });
      return returnCols;
    },
    getLevelHeaders(columns, level) {
      let returnHeaders = [];
      this.$_.forEach(columns, (column) => {
        if (column.level === level) {
          returnHeaders.push(column);
        } else {
          if (column.hasOwnProperty("child")) {
            returnHeaders.push.apply(
              returnHeaders,
              this.getLevelHeaders(column.child, level)
            );
          }
        }
      });
      return returnHeaders;
    },
    setSize() {
      /**
       * autoFullHeight: 윈도우 사이즈에 맞게 자동으로 높이 조절
       * gridHeight: 그리드의 높이를 지정하는 prop
       *
       * 그리드의 높이를 지정하지 않았으며 autoFullHeight를 true로 준 경우 높이를 윈도우 사이즈에 맞게 조절한다.
       * ※ 단, 자동으로 조절되는 높이가 200보다 작게 설정되지 않는다.
       * */
      if (!this.gridHeightAuto) {
        if (this.autoFullHeight && !this.gridHeight) {
          let offsettop =
            this.$refs["compo-table"].$el.getBoundingClientRect().top;
          let topsizes = [111, 208, 214];
          // let tempHeight = (document.getElementsByClassName('app-footer')[0].offsetTop - (this.$refs['compo-table'] ? this.$refs['compo-table'].$el.offsetTop : 250)) - 10;
          let tempHeight =
            window.innerHeight -
            (this.$refs["compo-table"] ? offsettop : 250) -
            (topsizes.indexOf(offsettop) < 0 ? 51 : 80);
          if (tempHeight < 300) {
            tempHeight = 300;
          }
          this.height = String(tempHeight) + "px";
        } else if (this.gridHeight) {
          this.height = this.gridHeight;
        } else {
          this.height = 300;
        }
        this.$emit("getTableHeight", this.height);
      } else {
        this.height = "";
      }
    },
    check(type) {
      // let blackList = ['link', 'cost', 'user', 'check', 'proxy', 'select', 'multiSelect', 'plant', 'html', 'img', 'text', 'textarea', 'number', 'dept', 'tag', 'custom', 'date', 'datetime', 'color', 'attach', 'vendor', 'badge'];
      // return (this.$_.indexOf(blackList, type) === -1);
      return type ? false : true;
    },
    checkEdit(type) {
      let whiteList = ["textarea", "number", "dept", "deptMulti"];
      return type && this.$_.indexOf(whiteList, type) > -1;
    },
    editTdCheck(type) {
      /**
       * type이 주어진 td이면서 editing이 필요한 컬럼인지 확인
       */
      let blackList = [
        "color",
        "html",
        "html2",
        "badge",
        "img",
        "cost",
        "cost2",
        "link",
        "tag",
        "custom",
        "proxy",
      ];
      return this.$_.indexOf(blackList, type) === -1;
    },
    /** link 클릭 */
    linkClick(props, col) {
      this.$emit("linkClick", props.row, col, props.pageIndex);
    },
    /** 사용자 팝업 */
    openUserPop(row, col, gubun) {
      this.setUserInfo = {
        row: row,
        col: col,
        gubun: gubun,
      };
      this.popupOptions.title = "사용자 검색";
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/common/user/userPop.vue"}`);
      this.popupOptions.width = "60%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (this.setUserInfo.gubun === "td") {
          this.$set(
            this.setUserInfo.row,
            this.setUserInfo.col.name,
            data[0].userName
          );
          this.$set(
            this.setUserInfo.row,
            this.setUserInfo.col.userId,
            data[0].userId
          );

          let deptCdText = this.setUserInfo.col.deptCd
            ? this.setUserInfo.col.deptCd
            : "deptCd";
          let deptNameText = this.setUserInfo.col.deptName
            ? this.setUserInfo.col.deptName
            : "deptName";
          let jobNameText = this.setUserInfo.col.jobName
            ? this.setUserInfo.col.jobName
            : "jobName";
          let spotNameText = this.setUserInfo.col.spotName
            ? this.setUserInfo.col.spotName
            : "spotName";
          this.$set(this.setUserInfo.row, deptCdText, data[0].deptCd);
          this.$set(this.setUserInfo.row, deptNameText, data[0].deptName);
          this.$set(this.setUserInfo.row, jobNameText, data[0].jobName);
          this.$set(this.setUserInfo.row, spotNameText, data[0].spotName);

          if (this.setUserInfo.row["editFlag"] !== "C") {
            this.setUserInfo.row["editFlag"] = "U";
            this.setUserInfo.row["chgUserId"] = this.$store.getters.user.userId;
          }
          this.$emit(
            "table-data-change",
            { row: this.setUserInfo.row },
            this.setUserInfo.col
          );
        } else {
          this.$set(this.setUserInfo.col, "value", data[0].userName);
          if (this.data && this.data.length > 0) {
            this.$_.forEach(this.data, (item) => {
              if (
                !item[this.checkDisableColumn] &&
                !(
                  this.setUserInfo.col.disableTarget &&
                  item[this.setUserInfo.col.disableTarget] !==
                    this.setUserInfo.col.disableCon
                )
              ) {
                this.$set(item, this.setUserInfo.col.name, data[0].userName);
                this.$set(item, this.setUserInfo.col.userId, data[0].userId);

                let deptCdText = this.setUserInfo.col.deptCd
                  ? this.setUserInfo.col.deptCd
                  : "deptCd";
                let deptNameText = this.setUserInfo.col.deptName
                  ? this.setUserInfo.col.deptName
                  : "deptName";
                let jobNameText = this.setUserInfo.col.jobName
                  ? this.setUserInfo.col.jobName
                  : "jobName";
                let spotNameText = this.setUserInfo.col.spotName
                  ? this.setUserInfo.col.spotName
                  : "spotName";
                this.$set(item, deptCdText, data[0].deptCd);
                this.$set(item, deptNameText, data[0].deptName);
                this.$set(item, jobNameText, data[0].jobName);
                this.$set(item, spotNameText, data[0].spotName);

                if (item["editFlag"] !== "C") {
                  item["editFlag"] = "U";
                  item["chgUserId"] = this.$store.getters.user.userId;
                }
              }
            });
            // 해당 경우에는 table-data-change emit을 날리지 않음
          }
        }
      }
    },
    vendorChange(props, col, vendor) {
      let vendorName =
        col.vendorInfo && col.vendorInfo.vendorName
          ? col.vendorInfo.vendorName
          : "vendorName";
      let address =
        col.vendorInfo && col.vendorInfo.address
          ? col.vendorInfo.address
          : "address";
      let bizNo =
        col.vendorInfo && col.vendorInfo.bizNo ? col.vendorInfo.bizNo : "bizNo";
      let chargeName =
        col.vendorInfo && col.vendorInfo.chargeName
          ? col.vendorInfo.chargeName
          : "chargeName";
      let phoneNo =
        col.vendorInfo && col.vendorInfo.phoneNo
          ? col.vendorInfo.phoneNo
          : "phoneNo";
      this.$set(props.row, vendorName, vendor.vendorName);
      this.$set(props.row, address, vendor.address);
      this.$set(props.row, bizNo, vendor.bizNo);
      this.$set(props.row, chargeName, vendor.chargeName);
      this.$set(props.row, phoneNo, vendor.phoneNo);

      if (props.row["editFlag"] !== "C") {
        props.row["editFlag"] = "U";
        props.row["chgUserId"] = this.$store.getters.user.userId;
      }
      this.$emit("table-data-change", props, col);
    },
    facilityChange(props, col, facility) {
      let facilityName =
        col.facilityInfo && col.facilityInfo.facilityName
          ? col.facilityInfo.facilityName
          : "facilityName";
      this.$set(props.row, facilityName, facility.facilityName);

      if (props.row["editFlag"] !== "C") {
        props.row["editFlag"] = "U";
        props.row["chgUserId"] = this.$store.getters.user.userId;
      }
      this.$emit("table-data-change", props, col);
    },
    equipChange(props, col, equip) {
      let equipName =
        col.equipInfo && col.equipInfo.equipmentName
          ? col.equipInfo.equipmentName
          : "equipmentName";
      let equipNo =
        col.equipInfo && col.equipInfo.equipmentNo
          ? col.equipInfo.equipmentNo
          : "equipmentNo";
      this.$set(props.row, equipName, equip.equipmentName);
      this.$set(props.row, equipNo, equip.equipmentNo);

      if (props.row["editFlag"] !== "C") {
        props.row["editFlag"] = "U";
        props.row["chgUserId"] = this.$store.getters.user.userId;
      }
      this.$emit("table-data-change", props, col);
    },
    /** 행 클릭 */
    rowClick(event, row, index) {
      // td 안에 있는 버튼을 클릭 시에는 row Click 이벤트가 먹히지 않도록 설정
      if (this.isInnerBtnClick) return;
      this.clicks++;
      this.selectedRowIndex = index;
      if (this.clicks === 1) {
        var self = this;
        this.timer = setTimeout(function () {
          /** 행 클릭 */
          self.$emit("rowClick", row, index);
          self.clicks = 0;
        }, this.delay);
      } else {
        /** 행 더블 클릭 */
        clearTimeout(this.timer);
        this.$emit("rowDblclick", row, index);
        this.clicks = 0;
      }
    },
    selectedRow(props) {
      if (!this.noHighLight) {
        return props.rowIndex === this.selectedRowIndex;
      } else {
        return false;
      }
    },
    setHighLight(props) {
      let returnVal = false;
      if (this.highLightInfo && this.highLightInfo.ids) {
        returnVal =
          this.$_.indexOf(
            this.highLightInfo.ids,
            props.row[this.highLightInfo.val]
          ) > -1;
      }
      return returnVal;
    },
    /** 체크박스 체크/체크해제 */
    changeSelection(detail) {
      if (detail.added) {
        // 전체 체크한 경우
        let noneSelected = this.$_.filter(detail.rows, (row) => {
          return Boolean(row[this.checkDisableColumn]);
        });
        setTimeout(() => {
          this.$_.forEach(noneSelected, (item) => {
            let idx = this.$_.findIndex(this.selected, (sel) => {
              return sel[this.rowKey] === item[this.rowKey];
            });
            if (idx > -1) {
              this.selected.splice(idx, 1);
            }
          });
        }, 100);
      } else {
        // 전체 체크해제한 경우
        let _selected = this.$_.filter(detail.rows, (row) => {
          return Boolean(row[this.checkDisableColumn]);
        });
        setTimeout(() => {
          // this.$set(this.$data, 'selected', _selected)
          this.$_.forEach(_selected, (item) => {
            let idx = this.$_.findIndex(this.selected, (sel) => {
              return sel[this.rowKey] === item[this.rowKey];
            });
            if (idx > -1) {
              this.selected.splice(idx, 1);
            }
          });
        }, 100);
      }
      setTimeout(() => {
        this.$emit("changeSelection", detail);
      }, 150);
    },
    getSelected() {
      this.$emit("getSelected", this.selected);
    },
    virtualScroll(details) {
      this.render = {
        from: details.from,
        to: details.to,
      };
    },
    isCreate(row, colIndex, rowIndex) {
      if (!this.$refs["compo-table"]) {
        return false;
      }
      let data = this.$refs["compo-table"].filteredSortedRows;
      let returnVal = true;
      if (this.merge && this.merge.length > 0) {
        let colName = "";
        if (colIndex > -1) {
          this.$_.forEach(this.merge, (item) => {
            if (item.index === colIndex) {
              colName = item.colName;
              return false;
            }
          });
        } else {
          colName = this.$_.clone(this.rowKey);
        }
        if (colName) {
          let viewRowIndex = rowIndex;
          let rows = this.$_.clone(data);
          if (this.initialPagination.rowsPerPage !== 0) {
            viewRowIndex =
              rowIndex > 0
                ? rowIndex % this.initialPagination.rowsPerPage
                : rowIndex;
            let lastPage = this.$_.ceil(
              data.length / this.initialPagination.rowsPerPage
            );
            let stSliceIdx =
              (this.initialPagination.page - 1) *
              this.initialPagination.rowsPerPage;
            if (lastPage !== this.initialPagination.page) {
              // 현재 보는 페이지가 마지막 페이지가 아닌경우
              let edSliceIdx =
                data.length -
                this.initialPagination.page *
                  this.initialPagination.rowsPerPage;
              rows = this.$_.drop(rows, stSliceIdx);
              rows = this.$_.dropRight(rows, edSliceIdx);
            } else {
              // 현재 보는 페이지가 마지막 페이지인 경우
              rows = this.$_.drop(rows, stSliceIdx);
            }
          } else {
            // 페이징을 사용하지 않는 경우 100 단위로 랜더링 됨으로 100을 기준으로 row를 정리
            viewRowIndex =
              rowIndex > 0 ? rowIndex - this.render.from : rowIndex;
            rows = this.$_.drop(rows, this.render.from);
            rows = this.$_.dropRight(rows, data.length - this.render.to - 1);
          }

          if (
            rows &&
            rows.length > 0 &&
            viewRowIndex > 0 &&
            rows[viewRowIndex]
          ) {
            // if (colIndex === 0) {
            //   console.log('## viewRowIndex: ', viewRowIndex, ' ## rows length: ', rows.length, ' ## render: ', this.render, ' ## rows: ', rows)
            // }
            if (
              rows[viewRowIndex - 1] &&
              rows[viewRowIndex - 1][colName] === rows[viewRowIndex][colName]
            ) {
              returnVal = false;
            }
          }
        }
      }
      // if (colIndex === 0) {
      //   console.log('## colIndex : ', colIndex, ' ## rowIndex : ', rowIndex, ' ## returnVal : ', returnVal, ' ## data : ', data[rowIndex]['level2'], ' ## data length : ', data.length)
      // }

      return returnVal;
    },
    getRowspan(colIndex, rowIndex) {
      let data = this.$refs["compo-table"].filteredSortedRows;
      let rowSpan = 0;
      if (this.merge && this.merge.length > 0) {
        let colName = "";
        if (colIndex > -1) {
          this.$_.forEach(this.merge, (item) => {
            if (item.index === colIndex) {
              colName = item.colName;
              return false;
            }
          });
        } else {
          colName = this.$_.clone(this.rowKey);
        }
        if (colName) {
          let viewRowIndex = rowIndex;
          let rows = this.$_.clone(data);
          if (this.initialPagination.rowsPerPage !== 0) {
            let lastPage = this.$_.ceil(
              data.length / this.initialPagination.rowsPerPage
            );
            let stSliceIdx =
              (this.initialPagination.page - 1) *
              this.initialPagination.rowsPerPage;
            viewRowIndex =
              rowIndex > 0
                ? rowIndex % this.initialPagination.rowsPerPage
                : rowIndex;
            if (lastPage !== this.initialPagination.page) {
              // 현재 보는 페이지가 마지막 페이지가 아닌경우
              let edSliceIdx =
                data.length -
                this.initialPagination.page *
                  this.initialPagination.rowsPerPage;
              rows = this.$_.drop(rows, stSliceIdx);
              rows = this.$_.dropRight(rows, edSliceIdx);
            } else {
              // 현재 보는 페이지가 마지막 페이지인 경우
              rows = this.$_.drop(rows, stSliceIdx);
            }
          } else {
            // 페이징을 사용하지 않는 경우 100 단위로 랜더링 됨으로 100을 기준으로 row를 정리
            viewRowIndex =
              rowIndex > 0 ? rowIndex - this.render.from : rowIndex;
            // viewRowIndex = rowIndex > 0 ? rowIndex % 101 : rowIndex
            rows = this.$_.drop(rows, this.render.from);
            rows = this.$_.dropRight(rows, data.length - this.render.to - 1);
          }
          if (rows && rows.length > 0 && rows[viewRowIndex]) {
            // if (colIndex === 0) {
            //   console.log('## rowIndex: ', rowIndex, '## viewRowIndex: ', viewRowIndex, ' ## rows length: ', rows.length, ' ## render: ', this.render, ' ## rows: ', rows)
            // }
            let postName = rows[viewRowIndex][colName];
            for (let i = viewRowIndex; i < rows.length; i++) {
              if (postName === rows[i][colName]) {
                rowSpan++;
              } else {
                break;
              }
            }
            // for (let i = 0; i < viewRowIndex; i++) {
            //   if (postName === rows[i][colName]) {
            //     rowSpan++;
            //   } else {
            //     break;
            //   }
            // }
            // if (colIndex === 0) {
            //   console.log('## rowSpan: ', rowSpan, '## viewRowIndex: ', viewRowIndex, ' ## rows length: ', rows.length, ' ## render: ', this.render, ' ## rows: ', rows)
            // }
          }
        }
      }

      if (rowSpan === 0) {
        rowSpan = 1;
      }

      return rowSpan > 1 ? rowSpan : void 0;
    },
    changePageNumber() {
      this.initialPagination.rowsPerPage = Number(
        this.$_.clone(this.pagePerRow.pageNumber)
      );
    },
    colChange(column) {
      if (!column.disabled) {
        if (column.check) {
          this.visibleColumns.push(column.name);
          let index = this.$_.indexOf(this.hiddenColumns, column.name);
          if (index > -1) {
            this.hiddenColumns.splice(index, 1);
          }
        } else {
          let index = this.$_.indexOf(this.visibleColumns, column.name);
          this.visibleColumns.splice(index, 1);
          this.hiddenColumns.push(column.name);
        }
        // 보이는 컬럼이 전체일경우 localStorage 삭제, 그외 컬럼visible정보를 localStorage에 저장
        if (this.visibleColumns.length == this.arrangColumns.length) {
          window.localStorage.removeItem(this.title);
        } else {
          window.localStorage.setItem(
            this.title,
            JSON.stringify(this.visibleColumns)
          );
        }
      }
    },
    checkInnerBtnEnable(btn, row, props) {
      let returnVal = false;
      if (btn.disableTarget) {
        // && col.disableCon && row[col.disableTarget]
        if (btn.disableCon) {
          returnVal = row[btn.disableTarget] !== btn.disableCon;
        } else {
          returnVal = !row[btn.disableTarget];
        }
      }
      return returnVal;
    },
    checkEnable(col, row, props) {
      let returnVal = false;
      if (col.disableTarget) {
        // && col.disableCon && row[col.disableTarget]
        returnVal = row[col.disableTarget] !== col.disableCon;
      }

      if (this.selection !== "none" && col.selectionAble) {
        returnVal = !props.selected;
      }
      return returnVal;
    },
    checkColorCloass(col, row, compareMethod) {
      let returnColorClass = "";
      if (col.colorTarget) {
        let _classes = col.colorClass;
        this.$_.forEach(_classes, (item) => {
          let bol = false;
          if (!compareMethod || compareMethod === "=") {
            bol = item.split(",")[0] === row[col.colorTarget];
          } else if (compareMethod === ">") {
            // 숫자만 가능
            bol = Number(item.split(",")[0]) > Number(row[col.colorTarget]);
          } else if (compareMethod === ">=") {
            // 숫자만 가능
            bol = Number(item.split(",")[0]) >= Number(row[col.colorTarget]);
          } else if (compareMethod === "<") {
            // 숫자만 가능
            bol = Number(item.split(",")[0]) < Number(row[col.colorTarget]);
          } else if (compareMethod === "<=") {
            // 숫자만 가능
            bol = Number(item.split(",")[0]) <= Number(row[col.colorTarget]);
          }
          if (bol) {
            returnColorClass = item.split(",")[1];
            return false;
          }
        });
      }
      if (col.colorTarget2) {
        if (
          row[col.colorTarget2] == "N" &&
          row[col.disableTarget] === col.disableCon
        ) {
          returnColorClass = "bg-grey-9 text-white";
        }
      }
      if (col.colorTarget3) {
        if (row[col.colorTarget3] == "C") {
          returnColorClass = "air-no-check-target";
        }
      }
      return returnColorClass;
    },
    checkUserTd(col, props) {
      if (!col.isInout) {
        return true;
      } else {
        if (props.row[col.inoutCol] === col.in) {
          return true;
        } else {
          return false;
        }
      }
    },
    headerDataChange(val, props, col, name) {
      if (col.headerType === "checkbox") {
        this.$emit("headerCheckboxChange", props, col, val);
        return;
      }
      // 필터링한 데이터만 바꾸기(데이터가 있을경우)
      let filterRows = this.$refs["compo-table"].filteredSortedRows;
      if (filterRows && filterRows.length > 0) {
        let valueCol = col.type !== "dept" ? col.name : col.deptCd;
        let nameCol = col.type !== "dept" ? "nothing" : col.name;
        this.$_.forEach(filterRows, (item) => {
          if (
            !item[this.checkDisableColumn] &&
            !(col.disableTarget && item[col.disableTarget] !== col.disableCon)
          ) {
            item[valueCol] = val;
            item[nameCol] = name;
            if (item["editFlag"] !== "C") {
              item["editFlag"] = "U";
              item["chgUserId"] = this.$store.getters.user.userId;
            }
          }
        });
      } else if (this.data && this.data.length > 0) {
        let valueCol = col.type !== "dept" ? col.name : col.deptCd;
        let nameCol = col.type !== "dept" ? "nothing" : col.name;
        this.$_.forEach(this.data, (item) => {
          if (
            !item[this.checkDisableColumn] &&
            !(col.disableTarget && item[col.disableTarget] !== col.disableCon)
          ) {
            item[valueCol] = val;
            item[nameCol] = name;
            if (item["editFlag"] !== "C") {
              item["editFlag"] = "U";
              item["chgUserId"] = this.$store.getters.user.userId;
            }
          }
        });
        // 해당 경우에는 table-data-change emit을 날리지 않음
        this.$emit("headerDataChange", props, col);
      }
    },
    headLinkClick(props, col) {
      this.$emit("headLinkClick", props, col, props.pageIndex);
    },
    datachange(props, col) {
      if (props.row["editFlag"] !== "C") {
        props.row["editFlag"] = "U";
        props.row["chgUserId"] = this.$store.getters.user.userId;
      }
      this.$emit("table-data-change", props, col);
    },
    uploadChange(data, props, col) {
      /**
       * F : 업로드 끝
       * R : 파일 삭제
       */
      if (props.row["editFlag"] !== "C") {
        props.row["editFlag"] = "U";
        props.row["chgUserId"] = this.$store.getters.user.userId;
      }
      this.$emit("uploadChange", data);
    },
    showPopupEdit(props, col) {
      if (col.type === "text") {
        this.$refs[
          "popup-edit-text_" + props.rowIndex + "_" + col.name
        ][0].focus();
        this.$refs[
          "popup-edit-text_" + props.rowIndex + "_" + col.name
        ][0].select();
      } else if (col.type === "textarea") {
        this.$refs[
          "popup-edit-textarea_" + props.rowIndex + "_" + col.name
        ][0].focus();
        this.$refs[
          "popup-edit-textarea_" + props.rowIndex + "_" + col.name
        ][0].select();
      }
    },
    changeSelect(val, props, col) {
      props.row[col.name] = val.value;
      // props.row[col.valueName] = val.value;

      /**
       * user 컴포넌트 사용하면서 내/외 구분에 따라 컴포넌틑 컨트롤 한다면
       * select 될때 userId의 값을 빈값처리
       */
      let userTypeCol = this.$_.find(props.cols, { type: "user" });
      if (userTypeCol && userTypeCol.isInout) {
        //  && val.value === userTypeCol.out
        props.row[userTypeCol.userId] = null;
        props.row[userTypeCol.field] = null;

        /**
         * 2021.08.24 kdh
         * 사용자뿐만 아니라 관련있는 항목도 null처리
         * relationCols (Array) 를 통해 처리
         */
        if (userTypeCol.relationCols && userTypeCol.relationCols.length > 0) {
          this.$_.forEach(userTypeCol.relationCols, (item) => {
            props.row[item] = null;
          });
        }
      }
      if (props.row["editFlag"] !== "C") {
        props.row["editFlag"] = "U";
        props.row["chgUserId"] = this.$store.getters.user.userId;
      }
      this.$emit("table-data-change", props, col);
    },
    /**
     * 2021.08.24 kdh
     * select box는 popup으로 표시하지 않음으로 해당 method 사용 X
     */
    // setNameOfComboItems(comboItems, itemValue, itemText, value) {
    //   let _itemValue = itemValue ? itemValue : 'code';
    //   let _itemText = itemText ? itemText : 'codeName';
    //   if (comboItems && comboItems.length > 0) {
    //     let text = '';
    //     this.$_.forEach(comboItems, item => {
    //       if (item[_itemValue] === value) {
    //         text = item[_itemText];
    //         return false;
    //       }
    //     })
    //     return text;
    //   } else {
    //     return '';
    //   }
    // },
    clickFullScreen() {
      if (this.fullscreen === false) {
        this.fullscreen = true;
        this.height = "100%";
      } else {
        this.fullscreen = false;
        setTimeout(() => {
          this.setSize();
        }, 100);
      }
    },
    innerBtnClicked(col, props, btn) {
      this.isInnerBtnClick = true;
      setTimeout(() => {
        this.isInnerBtnClick = false;
      }, 100);
      this.$emit("innerBtnClicked", col, props, btn);
    },
    callbackProxy(data, color, props, col) {
      let refName = "proxy_" + props.rowIndex + "_" + col.name;
      this.$emit("callbackProxy", data, props, col);
      this.$refs[refName][0].hide();
    },
    setHeaderClass(col) {
      let returnVal =
        "text-center " +
        (col.hasOwnProperty("headerClass") ? col.headerClass : "");
      return returnVal;
    },
    setTagColor(col, data) {
      if (col.colorItems) {
        let color =
          this.$_.find(col.colorItems, { code: data }) ||
          this.$_.find(col.colorItems, { stepperMstCd: data });
        return color ? color.attrVal1 || color.colorClass : "";
      } else {
        return "";
      }
    },
    setTagName(col, data) {
      if (col.colorItems) {
        let color =
          this.$_.find(col.colorItems, { code: data }) ||
          this.$_.find(col.colorItems, { stepperMstCd: data });
        return color ? color.codeName || color.stepperMstNm : "";
      } else {
        return "";
      }
    },
    setHeaderStyle(col) {
      let width = col.style
        ? this.$_.trim(this.$_.split(col.style, ":")[1])
        : "";
      let returnText = width
        ? "min-width: " + width + ";max-width: " + width
        : "";

      // + ';max-width: ' + width : '';
      if (returnText !== "") {
        returnText += ";white-space: normal";
      }
      if (col.headerColor) {
        returnText += ";background: " + col.headerColor;
      }
      if (col.fix && col.style) {
        /**
         * fix를 하기 위해서는 width가 있어야함
         */
        let left = 0;
        if (this.selection !== "none" && this.editable) {
          /**
           * 체크박스가 있는 경우 clientLeft
           */
          left = 24;
        }
        let colIdx = this.$_.findIndex(this.arrangColumns, { name: col.name });
        if (colIdx > 0) {
          for (let i = 0; i < colIdx; i++) {
            let _left = this.$_.trim(
              this.arrangColumns[i].style.replace(/width[:]|px/g, "")
            );
            left += _left && !isNaN(_left) ? Number(_left) : 0;
          }
        }
        returnText +=
          ";position: sticky;background-color: #EEF2FD;z-index: 3;left:" +
          left +
          "px;";
      }
      return returnText;
    },
    setTdText(item, col) {
      let text = item[col.name] ? item[col.name] : "";
      if (text && this.$_.indexOf(["number", "cost", "cost2"], col.type) > -1) {
        text = toThousandFilter(text);
      } else {
        text =
          text && isNaN(text)
            ? text.replace(
                /(?:\r\n|\n\r|\r|\n)/g,
                '<br style="mso-data-placement:same-cell;" />'
              )
            : text;
      }
      return text;
    },
    downloadExcelHtml() {
      let table =
        this.$refs["compo-table"].$el.getElementsByTagName("table")[0];
      let exportTable = document.createElement("table");
      const alignCenter = "text-align: center !important;";
      const alignLeft = "text-align: left !important;";
      const alignRight = "text-align: right !important;";
      /** ####################################################################### */
      /** ############################## HEADER ################################# */
      /** ####################################################################### */
      let exportTHeader = document.createElement("thead");
      // 마지막 tr은 제외처리
      let headers = table
        .getElementsByTagName("thead")[0]
        .getElementsByTagName("tr");
      const thStyle =
        "background: #EEEEEE; color: #000; font-weight: 350; font-size: 0.8rem; padding: 6px 4px !important;border: 1px solid #D9D9D9;";
      const thFirstStyle = "border-top: 2px solid #1ab394 !important; top: 0;";
      exportTHeader.appendChild(document.createElement("tr"));
      for (let i = 0; i < headers.length - 1; i++) {
        let exportTr = document.createElement("tr");
        let ths = headers[i].getElementsByTagName("th");
        for (let j = 0; j < ths.length; j++) {
          if (ths[j].className.includes("checkbox-th")) continue; // 체크박스열 제거
          let exportTh = document.createElement("th");
          exportTh.style.cssText =
            ths[j].style.cssText + thStyle + (i === 0 ? thFirstStyle : "");
          exportTh.innerText = this.$_.replace(
            ths[j].textContent,
            "arrow_upward",
            ""
          );
          // exportTh.colspan = this.arrangColumns[j].colspan ? this.arrangColumns[j].colspan : 1
          exportTh.setAttribute(
            "colSpan",
            ths[j].getAttribute("colSpan") ? ths[j].getAttribute("colSpan") : 1
          );
          exportTh.setAttribute(
            "rowSpan",
            ths[j].getAttribute("rowSpan") ? ths[j].getAttribute("rowSpan") : 1
          );
          exportTr.appendChild(exportTh);
        }
        exportTHeader.appendChild(exportTr);
      }
      exportTable.appendChild(exportTHeader);

      /** ####################################################################### */
      /** ################################ BODY ################################# */
      /** ####################################################################### */
      const tdStyle =
        ";border: 1px solid #D9D9D9;font-size: 0.8rem;color: #616161;";
      let exportTBody = document.createElement("tbody");
      if (this.data && this.data.length > 0) {
        this.data.forEach((item, idx) => {
          let exportTr = document.createElement("tr");
          this.$_.forEach(this.arrangColumns, (col) => {
            // 보이는 컬럼들만 데이터 적용
            if (this.$_.indexOf(this.visibleColumns, col.name) > -1) {
              let exportTd = document.createElement("td");
              exportTd.style.cssText =
                col.style +
                tdStyle +
                (col.align === "left"
                  ? alignLeft
                  : col.align === "right"
                  ? alignRight
                  : alignCenter);
              exportTd.innerHTML = this.setTdText(item, col);
              // exportTd.innerText = this.setTdText(item, col)
              exportTr.appendChild(exportTd);
            }
          });
          exportTBody.appendChild(exportTr);
        });
        exportTable.appendChild(exportTBody);
      }

      // xlsx 파일 (style 미적용)
      // let wb = XLSX.utils.book_new();
      // let newWorksheet = XLSX.utils.table_to_sheet(exportTable);
      // XLSX.utils.book_append_sheet(wb, newWorksheet, 'Sheet');
      // XLSX.writeFile(wb, this.convertTitle + '-' + this.$comm.moment().format('YYYYMMDD') + '.xlsx');

      // xls 파일 (style적용)
      this.export(exportTable);
    },
    export(table) {
      let styles = "";

      let ctx = { worksheet: "sheet", table: table.innerHTML };
      let template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">' +
        '<meta http-equiv="content-type" content="text/plain; charset=UTF-8">' +
        "<head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->" +
        styles +
        "</head><body><table>{table}</table></body></html>";

      let uri = "data:application/vnd.ms-excel;base64,";
      let base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      };
      let format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };
      let link = document.createElement("a");
      link.href = uri + base64(format(template, ctx));
      link.download =
        this.convertTitle + "-" + this.$comm.getTodayNone() + ".xls";
      link.click();
    },
    setColColor(col) {
      if (col && col.colColor) {
        return col.colColor.bgColor + " " + col.colColor.textColor;
      } else {
        return "";
      }
    },
    tdClick(props, col, self) {
      if (!this.checkClickFlag) return;
      let rowspan = 0;
      let target = self.target;
      if (target.localName === "span") {
        target = target.offsetParent;
      }
      rowspan = target.getAttribute("rowspan")
        ? target.getAttribute("rowspan")
        : 0;
      /**
       * selection이 있는 경우 실행 selected rowKey
       *
       * selected check
       */
      if (this.check(col.type) || col.type === "html") {
        if (this.selection === "multiple") {
          let data = this.$refs["compo-table"].filteredSortedRows;
          let filterData = [];
          if (rowspan > 0) {
            filterData = this.$_.filter(data, (item) => {
              return item[col.name] === props.row[col.name];
            });
          } else {
            filterData = this.$_.filter(data, (item) => {
              return item[this.rowKey] === props.row[this.rowKey];
            });
          }
          if (filterData && filterData.length > 0) {
            this.$_.forEach(filterData, (item) => {
              if (
                this.$_.findIndex(this.selected, (selectItem) => {
                  return selectItem[this.rowKey] === item[this.rowKey];
                }) === -1
              ) {
                this.selected.push(item);
              }
            });
          }
        } else if (this.selection === "single") {
          let data = this.$refs["compo-table"].filteredSortedRows;
          this.selected = this.$_.filter(data, (item) => {
            return item[this.rowKey] === props.row[this.rowKey];
          });
        }
      }
    },
    tdRightClick(props, col) {
      if (!this.checkClickFlag) return;
      /**
       * selection이 있는 경우 실행 selected rowKey
       *
       * selected uncheck
       */
      if (this.check(col.type)) {
        if (this.selection === "multiple" || this.selection === "single") {
          let data = this.$refs["compo-table"].filteredSortedRows;
          let filterData = this.$_.filter(data, (item) => {
            return item[col.name] === props.row[col.name];
          });
          if (filterData && filterData.length > 0) {
            this.$_.forEach(filterData, (item) => {
              if (
                this.$_.findIndex(this.selected, (selectItem) => {
                  return selectItem[this.rowKey] === item[this.rowKey];
                }) > -1
              ) {
                this.selected = this.$_.reject(this.selected, item);
                // this.$set(this.selected, item, )
              }
            });
          }
        }
      }
    },
    setTimePickerOptions(props, col) {
      if (
        col.type === "datetime" &&
        this.tableTimePickerOptions &&
        this.tableTimePickerOptions.start &&
        col.name === this.tableTimePickerOptions.colName
      ) {
        let stepSplit = this.$_.split(this.tableTimePickerOptions.step, ":");
        let curStart =
          props.rowIndex > 0
            ? this.data[props.rowIndex - 1][col.name]
            : this.tableTimePickerOptions.start;
        let start = this.$comm
          .moment(this.$comm.getToday() + " " + curStart)
          .add(Number(stepSplit[1]), "m")
          .add(Number(stepSplit[0]), "h")
          .format("HH:mm");
        // start = this.$comm.moment(this.$comm.getToday() + ' ' + start).add((Number(stepSplit[0])), 'h').format('HH:mm');
        return {
          start: start,
          step: this.tableTimePickerOptions.step,
          end: this.tableTimePickerOptions.end,
        };
      } else {
        return undefined;
      }
    },
    fileDown(file) {
      let thisVue = this;
      let accept = this.$comm.getAccept(file.fileExt);
      this.$http.url = this.fileDownUrl;
      this.$http.type = "GET";
      this.$http.param = {
        sysAttachFileId: file.sysAttachFileId,
      };
      this.$http.request((_result) => {
        let url = window.URL || window.webkitURL;
        let link = document.createElement("a");
        let blob = thisVue.$comm.base64ToBlob(_result.data, accept);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, file.oriFileNm);
        } else {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = file.oriFileNm;
          link.click();
        }
      });
    },
    setPreview(file) {
      if (!file) return;
      // 이미지인  경우
      if (file.sysAttachFileId) {
        this.$http.url = this.previewUrl;
        this.$http.type = "GET";
        this.$http.param = {
          sysAttachFileId: file.sysAttachFileId,
        };
        this.$http.request(
          (_result) => {
            this.preview.isShow = true;
            this.preview.sysAttachFileId = file.sysAttachFileId;
            this.preview.src =
              "data:" + file.contentType + ";base64," + _result.data;
            this.preview.oriFileNm = file.oriFileNm;
            this.preview.fileSize = file.fileSize;
            this.preview.fileExt = file.fileExt;
          },
          (_error) => {}
        );
      }
    },
    tdStyle(col) {
      let text = "";
      if (col.innerBtn && col.btns) {
        text = "padding-top:10px !important;";
      }
      if (col.color) {
        text += "font-weight:800; color:" + col.color + ";";
      }
      if (col.currentDay) {
        text += "background-color: #eaffdd;";
      }
      if (col.fix && col.style) {
        /**
         * fix를 하기 위해서는 width가 있어야함
         */
        let left = 0;
        if (this.selection !== "none" && this.editable) {
          /**
           * 체크박스가 있는 경우 clientLeft
           */
          left = 24;
        }
        let colIdx = this.$_.findIndex(this.arrangColumns, { name: col.name });
        if (colIdx > 0) {
          for (let i = 0; i < colIdx; i++) {
            let _left = this.$_.trim(
              this.arrangColumns[i].style.replace(/width[:]|px/g, "")
            );
            left += _left && !isNaN(_left) ? Number(_left) : 0;
          }
        }
        text +=
          ";position: sticky;background-color: #f7f7f7;z-index: 1;left:" +
          left +
          "px;";
      }
      return text;
    },
    uploadResearchSetting(row, uploadCols) {
      if (uploadCols && uploadCols.length > 0) {
        this.uploadResearchObj = {
          row: row,
          uploadCols: uploadCols,
        };
      }
    },
    uploadResearch() {
      if (
        this.uploadResearchObj &&
        this.uploadResearchObj.uploadCols &&
        this.uploadResearchObj.uploadCols.length > 0
      ) {
        this.$_.forEach(this.uploadResearchObj.uploadCols, (col) => {
          this.$set(
            this.uploadResearchObj.row,
            `${this.$const.UPLOAD_RESEARCH_COL}_${col}`,
            uid()
          );
        });
      }
    },
    reRend() {
      this.tableKey = uid();
    },
  },
};
</script>
<style scoped>
.default-table tbody .q-tr [rowspan] {
  vertical-align: top;
}
.tdBackGroundColor {
  display: inline-block;
  width: 30px;
  height: 25px;
  background: #26a69a;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
  padding-top: 2px;
}
.tdBackGroundColorNone {
  display: inline-block;
  width: 30px;
  height: 25px;
  background: #e4e4e4;
  border-radius: 10px;
  font-weight: 700;
  padding-top: 2px;
  opacity: 0.5;
}
@supports (position: sticky) or (position: -webkit-sticky) {
  .default-table
    tbody
    .q-tr
    [rowspan]:not(.edittd-text):not(.edittd-textarea):not(.edittd-number):not(
      .edittd-date
    ):not(.edittd-select)
    > span:not(.descript-span) {
    position: sticky;
    top: calc(var(--my-var) + 2px);
    /* z-index: -1; */
    /* top: 35px; */
  }
}
</style>

<style lang="sass">
.tableDatepicker .q-field__before
  padding-top: 1px
.tableDatepicker .q-field--dense .q-field__control, .tableDatepicker .q-field--dense .q-field__marginal
  height: min-content !important
  min-height: 26px !important
.tableDatepicker .q-field--filled .q-field__control
  border: 0px solid rgba(0,0,0,0.15) !important
  padding: 0px
.tableDatepicker .q-field__label
  display: none
.tableDatepicker .q-field__inner, .tableDatepicker .q-field__control-container
  padding: 0px !important
.tableDatepicker .q-field__native.row
  display: inline-block !important
  text-align: center
.isDashboardTable
  border-width: 0px !important
  box-shadow: 0 0 0 0 !important
  .q-table tr
    border: 0px !important
  thead tr:first-child th
    border: 0px !important
  tbody
    border: 0px !important
  tbody td
    border: 0px !important
  .q-tr td:first-child
    border: 0px !important
  .q-tr td:last-child
    border: 0px !important
  thead tr th
    background: #f8f8f8
    border-bottom: 1px solid #e8e8e8 !important
.cardselectarea
  display: inline-flex
  float: left !important
  .customSelect
    padding: 0px !important
    .q-field__inner
      padding: 0px !important
  .customSelect.q-field--dense .q-field__control, .customSelect.q-field--dense .q-field__marginal
    height: 38px !important
    min-height: 38px !important

.default-table-no-padding
  padding: 0px
  .q-table__top
    padding: 0px !important

.fold-table
  .q-table__middle
    display: none
  .q-table__bottom
    display: none

.air-no-check-target
  display: none
</style>