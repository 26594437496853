<template>
  <div>
    <q-list bordered dense separator class="custom-file-column">
      <q-item
        v-for="(file, idx) in fileList"
        :key="idx"
        clickable
        v-ripple
        @click="fileDown(file)"
      >
        <q-item-section side>
          <q-avatar rounded size="sm">
            <img :src="setIcon(file)" />
          </q-avatar>
        </q-item-section>

        <q-item-section>
          <q-item-label class="text-primary">
            {{ file.oriFileNm }}
            <q-tooltip content-class="bg-red" :offset="[10, 10]">
              {{ file.oriFileNm }}
            </q-tooltip>
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from "@/js/selectConfig";
import mixinCommon from "./js/mixin-common";
export default {
  /* attributes: name, components, props, data */
  name: "CFileColumn",
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    fileIds: {
      type: String,
      default: function () {
        return null;
      },
    },
    rowIndex: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    preview: {
      type: Object,
      default: function () {
        return {
          isShow: false,
          sysAttachFileId: "",
          src: "",
          oriFileNm: "",
          fileSize: 0,
          fileExt: "",
        };
      },
    },
  },
  data() {
    return {
      fileList: [],
      uploadedFilesUrl: "",
      fileDownUrl: "",
    };
  },
  computed: {
    fileIdSplit() {
      let files = [];
      if (this.fileIds) {
        files = this.fileIds.split(",");
      }
      return files;
    },
  },
  watch: {
    fileIdSplit: {
      handler: function (c, p) {
        this.getUploadedFiles();
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.init();
  },
  mounted() {},
  beforeDestroy() {},
  destroyed() {},
  updated() {},
  /* methods */
  methods: {
    init() {
      // url setting
      this.uploadedFilesUrl = selectConfig.com.upload.fileList.url;
      this.fileDownUrl = selectConfig.com.upload.fileDown.url;
      this.getUploadedFiles();
    },
    getUploadedFiles() {
      if (!this.fileIdSplit || this.fileIdSplit.length === 0) {
        this.$set(this.$data, "fileList", []);
        return;
      }
      this.$http.url = this.uploadedFilesUrl;
      this.$http.type = "GET";
      this.$http.param = {
        fileIds: this.fileIdSplit,
      };
      this.$http.request((_result) => {
        if (_result.data && _result.data.length > 0) {
          // 신규로 등록됨에 따라 임시로 만든 taskKey를 반환처리
          this.fileList = _result.data;
          this.$_.forEach(this.fileList, (item) => {
            let encstr = item.previewImage;
            let url = window.URL || window.webkitURL;
            let blob = this.$comm.base64ToBlob(encstr, "image/jpeg");
            let imgUrl = url.createObjectURL(blob);
            item.imgUrl = imgUrl;
          });
        } else {
          this.$set(this.$data, "fileList", []);
        }
      });
    },
    setIcon(file) {
      let icon = "";
      switch (file.fileExt) {
        case "xlsx":
        case "xlx":
          // 엑셀
          icon = require("@/assets/images/excel_small.png");
          break;
        case "ppt":
        case "pptx":
          // 파워포인트
          icon = require("@/assets/images/ppt_small.png");
          break;
        case "word":
          // 워드
          icon = require("@/assets/images/work_small.png");
          break;
        case "pdf":
          // pdf
          icon = require("@/assets/images/pdf_small.png");
          break;
        case "png":
        case "gif":
        case "jpg":
        case "jpeg":
          // image
          icon = require("@/assets/images/image_small.png");
          break;
        default:
          // txt
          icon = require("@/assets/images/txt_small.png");
      }
      return icon;
    },
    fileDown(file) {
      if (
        file.fileExt.toLowerCase() == "png" ||
        file.fileExt.toLowerCase() == "gif" ||
        file.fileExt.toLowerCase() == "jpg" ||
        file.fileExt.toLowerCase() == "jpeg"
      ) {
        // 이미지의 경우 미리보기 창 오픈
        this.setPreview(file);
      } else {
        let thisVue = this;
        let accept = this.$comm.getAccept(file.fileExt);
        this.$http.url = this.fileDownUrl;
        this.$http.type = "GET";
        this.$http.param = {
          sysAttachFileId: file.sysAttachFileId,
        };
        this.$http.request((_result) => {
          let url = window.URL || window.webkitURL;
          let link = document.createElement("a");
          let blob = thisVue.$comm.base64ToBlob(_result.data, accept);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, file.oriFileNm);
          } else {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = file.oriFileNm;
            link.click();
          }
        });
      }
    },
    setPreview(file) {
      if (!file) return;
      // 이미지인  경우
      if (file.sysAttachFileId) {
        this.$http.url = selectConfig.com.upload.preview.url;
        this.$http.type = "GET";
        this.$http.param = {
          sysAttachFileId: file.sysAttachFileId,
        };
        this.$http.request(
          (_result) => {
            this.preview.isShow = true;
            this.preview.sysAttachFileId = file.sysAttachFileId;
            this.preview.src =
              "data:" + file.contentType + ";base64," + _result.data;
            this.preview.oriFileNm = file.oriFileNm;
            this.preview.fileSize = file.fileSize;
            this.preview.fileExt = file.fileExt;
          },
          (_error) => {}
        );
      }
    },
  },
};
</script>
<style scoped>
.custom-file-column .q-item__label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
}
</style>