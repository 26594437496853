<template>
  <div class="header-dots header-sitemap">
    <span slot="button-content">
      <q-btn
        flat
        dense
        round
        color="white"
        class="topnoti"
        @click="siteMapOpen"
        icon="local_library">
        <q-tooltip>{{$language('전체 메뉴')}}</q-tooltip>
      </q-btn>
    </span>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: "SiteMap",
  components: {
    // "font-awesome-icon": FontAwesomeIcon
  },

  computed: {
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        param: {},
        closeCallback: null,
      },
    };
  },
  props: {
    headerbg: String
  },
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  methods: {
    siteMapOpen() {
      this.popupOptions.title = '전체 메뉴'
      this.popupOptions.target = () => import(`${'./SiteMapFull.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
<style lang="sass">
.header-sitemap
  margin-right: 10px
</style>