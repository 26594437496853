<!--
목적 : 공지 팝업용 컴포넌트
-->
<template>
  <q-dialog v-model="noticedialog" position="top">
    <q-card class="mainpoplayerCard">
      <q-bar class="bg-orange-custom text-white mainpoplayerHead">
        <q-icon color="white" name="mail" />
        <q-toolbar-title><span class="text-weight-bold">{{$language('공지사항')}}</span></q-toolbar-title>
        <q-btn flat round dense icon="close" v-close-popup />
      </q-bar>
      <q-card-section class="row items-center no-wrap mainpoplayer">
        <q-list bordered style="width: 100%">
          <q-expansion-item v-for="tag in popNotices"
            :key="tag.sysNoticeId"
            group="noticepops"
            header-class="bg-gray-custom text-gray"
            expand-icon-class="text-gray"
            v-model="tag.expanded">
            <template v-slot:header>
              <q-item-section avatar>
                <q-icon color="gray" name="check" size="13px" />
              </q-item-section>
              <q-item-section>
                <b>{{tag.noticeTitle}}</b>
              </q-item-section>
            </template>
            <q-card>
              <q-card-section>
                <div v-html="tag.noticeContents"></div>
              </q-card-section>
              <q-card-section>
                <c-upload-list
                  :attachInfo="{taskClassCd: 'NOTICE', taskKey: tag.sysNoticeId}"
                  :editable="false"
                  :label="$language('첨부파일')">
                </c-upload-list>
              </q-card-section>
            </q-card>
            <q-card v-show="tag.dayViewDisable">
              <q-card-section>
                <q-toggle
                  size="sm"
                  class="anymoreNotice"
                  v-model="tag.dayView"
                  checked-icon="check"
                  color="red"
                  :label="$language('더이상 보지 않기')" 
                  unchecked-icon="clear"
                  @input="updateTodayChk(tag.sysNoticeId)"
                />
              </q-card-section>
            </q-card>
          </q-expansion-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import mixinCommon from './js/mixin-common'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'c-notice-popup',
  mixins: [mixinCommon],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      noticedialog: false,
      noticePopUrl: '',
      popNotices: [],
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      this.noticePopUrl = selectConfig.sys.notice.main.url;
      this.getNoticePop();
    },
    updateTodayChk(_noticeId) {
      let mainPopNoticeId = window.localStorage.getItem('mainPopNoticeId');
      if (mainPopNoticeId === null) {
        window.localStorage.setItem('mainPopNoticeId', _noticeId + ',');
      } else {
        if (mainPopNoticeId.indexOf(_noticeId) < 0) {
          window.localStorage.setItem('mainPopNoticeId', mainPopNoticeId + _noticeId + ',');
        } else {
          window.localStorage.setItem('mainPopNoticeId', mainPopNoticeId.replace(_noticeId + ',',''));
        }
      }

    },
    getNoticePop() {
      this.popNotices = [];
      this.$http.url = this.noticePopUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        vendorFlag: 'N',
      }
      this.$http.request((_result) => {
        let mainPopNoticeId = window.localStorage.getItem('mainPopNoticeId');
        if (mainPopNoticeId !== null) {
          let icnt=0;
          this.$_.forEach(_result.data, item => {
            if (mainPopNoticeId.indexOf(item.sysNoticeId) < 0) {
              item.dayViewDisable = true;
              if (icnt == 0) {
                item.expanded = true;
              } else {
                item.expanded = false;
              }
              this.popNotices.push(item);
              icnt++;
            }
          })
          if (this.popNotices.length == _result.data.length) {
            window.localStorage.removeItem('mainPopNoticeId');
          }
        } else {
          this.popNotices = _result.data;
          let icnt=0;
          this.$_.forEach(this.popNotices, item => {
            item.dayViewDisable = true;
            if (icnt == 0) {
              item.expanded = true;
            } else {
              item.expanded = false;
            }
            icnt++;
          })
        }
        if (this.popNotices.length > 0) {
          this.noticedialog = true
        }
      },);
    },
  },
};
</script>
<style lang="scss">
.anymoreNotice {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.mainpoplayer .q-item {
  height: 28px !important;
  padding: 3px 16px !important;
}

.mainpoplayer {
  padding: 2px !important
}

.mainpoplayer > .q-list > .q-expansion-item {
  border-bottom: 1px solid #fff;
}

.mainpoplayerCard {
  min-width: 1024px;
  width: 1024px;
  border-radius: 6px 6px 0 0 !important;
  .q-item__section--avatar {
    min-width: 30px !important;
    width: 30px !important;
    padding-right: 0px !important;
  }
}
.mainpoplayerHead {
  height: 50px;
}
</style>