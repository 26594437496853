<!--
목적 : 수급업체 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customVendor"
      :class="[(stype==='customVendor' ? 'customVendor' : 'tableVendor')]"
      :dense="dense"
      stack-label
      filled
      color="orange-custom"
      :label="label"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{label}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        {{valueText}}
      </template>

      <template v-slot:append>
        <!-- <div v-if="!disabled&&editable" class="vendor-self cursor-pointer" @click="self" >자체</div> -->
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'c-vendor-multi',
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    stype: {
      type: String,
      default: 'customVendor',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'edit',
    },
    plantCd: {
      type: String,
      default: '',
    },
    processCd: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    haveProcess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listUrl: '',
      vendors: [],
      vValue: null,
      valueText: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
    disabled: {
      handler: function () {
        if (!this.disabled) {
          this.$refs['customVendor'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
        }
      },
      deep: false,
    }
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.setValueText();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customVendor'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customVendor'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        let _list = [];
        this.$_.forEach(this.$store.state.systemInfo.vendors, (_item) => {
          if (this.vValue.split(",").indexOf(_item.vendorCd) > -1) {
            _list.push(_item.vendorName);
          }
        });
        this.valueText = _list.join(',');
        return _list;
      } else {
        return null;
      }
    },
    search() {
      this.popupOptions.title = '수급업체 검색'; 
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.plantCd,
        processCd: this.processCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let emitVendors = [];
        let value = [];
        if (this.vValue) {
          value = this.vValue.split(",")
        }
        this.$_.forEach(data, item => {
          if (this.$_.indexOf(value, item.vendorCd) === -1) {
            emitVendors.push(item)
          }
        })
        let vendorCd = this.$_.cloneDeep(this.vValue);
        
        if (emitVendors && emitVendors.length > 0) {
          if (vendorCd) {
              vendorCd += ',' + this.$_.map(emitVendors, 'vendorCd').toString()
              this.valueText += ',' + this.$_.map(emitVendors, 'vendorName').toString()
          } else {
            vendorCd = this.$_.map(emitVendors, 'vendorCd').join(",")
            this.valueText = this.$_.map(emitVendors, 'vendorName').join(",")
          }
        }
        
        this.$emit('datachange', vendorCd);
        this.$emit('input', vendorCd);
      }
    },
    reset() {
      this.valueText = '';
      this.$emit('datachange', null);
      this.$emit('input', null);
    },
  },
};
</script>
<style lang="sass">
.customVendor
  padding-bottom: 20px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customVendor:hover
  .dateCloseIcon
    display: block
.customVendor.q-field--dense .q-field__control, .customVendor.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important
.customVendor.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.tableVendor
  padding-bottom: 0px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.tableVendor:hover
  .dateCloseIcon
    display: block
.tableVendor.q-field--dense .q-field__control, .tableVendor.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important
.tableVendor.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
</style>