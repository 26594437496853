<!--
  TODO : 버튼 공통 모듈
-->
<template>
  <q-card bordered flat :class="[radiusFlag ? 'custom-card' : 'custom-card-noradius', topClass, (noMarginPadding ? 'padding-margin-none' : '')]">
    <q-card-section v-if="!noHeaderCard" class="q-pa-xs cardhead" :class="'bg-' + bgClass">
      <div class="row items-center no-wrap">
        <div v-if="isTitle" class="col">
          <div class="text-h6" :class="'text-' + titleClass">
            <i v-if="isCardIcon" class="pe-7s-angle-right-circle" :class="'text-' + titleClass"></i>
            {{convertTitle}}
            <slot name="card-description"></slot>
          </div>
        </div>
        <template v-else>
          <slot name="card-title-custom"></slot>
        </template>
        <div class="col-auto">
          <slot name="card-button"></slot>
        </div>
        <div class="col-auto cardselectarea">
          <slot name="card-select"></slot>
        </div>
        <div class="float-right">
          <q-btn
            v-if="collapsed || title=='개정'"
            flat
            dense
            class="card-collapse"
            color="grey-6"
            :icon="expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
            @click="expanded = !expanded">
          </q-btn>
        </div>
      </div>
    </q-card-section>
    <q-separator v-if="!noHeaderCard"  />
    <q-card-section v-show="expanded"
      :class="['q-pa-sm row cardheader customCardbody', !displayFlax ? 'displayNoFlax' : '']"
      :style="height ? { 'height': height, 'min-height': height, 'max-height': height } : { 'height': 'auto' }">
      <slot name="card-detail"></slot>
    </q-card-section>
  </q-card>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-card',
  mixins: [mixinCommon],
  props: {
    radiusFlag: {
      type: Boolean,
      default: true,
    },
    displayFlax: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: 'auto',
    },
    bgClass: {
      type: String,
      default: 'grey-3',
    },
    titleClass: {
      type: String,
      default: 'grey-8',
    },
    collapsed: { // 카드 접기/펼치기 기능 사용여부
      type: Boolean,
      default: false,
    },
    noHeader: { // 헤더사용/미사용
      type: Boolean,
      default: false,
    },
    topClass:  {
      type: String,
      default: '',
    },
    noMarginPadding:  {
      type: Boolean,
      default: false,
    },
    isCardIcon: { 
      type: Boolean,
      default: true,
    },
    isRevision: { 
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // expanded: this.title=='개정' ? false : true,
      expanded: true,
    };
  },
  watch: {
    isRevision() {
      if (this.isRevision) {
        // true로 바뀐 경우
        this.expanded = true;
      } else if (!this.isRevision && this.title=='개정') {
        this.expanded = false;
      }
    }
  },
  computed: {
    isTitle() {
      return Boolean(this.title)
    },
    noHeaderCard() {
      return this.noHeader;
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  mounted() {
  },
  /* methods */
  methods: {
    clickCardCollapse() {

    }
  },
};
</script>
<style lang="sass">

.custom-card
  // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important
  box-shadow: 0
  .cardhead
    padding: 0px 5px 0px 10px
    .text-h6
      font-size: 0.95em
      font-weight: 600
      line-height: 26px
      i.pe-7s-angle-right-circle
        font-weight: 600
  .card-collapse
    font-size:10px
    width: 20px

.custom-card-noradius
  box-shadow: 0
  .cardhead
    padding: 0px 5px 0px 10px
    .text-h6
      font-size: 0.95em
      font-weight: 600
      line-height: 26px
      i.pe-7s-angle-right-circle
        font-weight: 600
  .card-collapse
    font-size:10px
    width: 20px
.q-btn-group
  button
    margin-right: 2px
  button:last-child
    margin-right: 0px
.customCardbody
  padding: 12px 0 0 0 !important
  overflow-y: auto
  margin-top: 10px !important
  margin-bottom: -6px !important

.padding-margin-none
  .customCardbody
    padding: 0px !important
    margin: 0px !important
    // padding-bottom: 0px !important
    // margin-bottom: 0px !important

.cardClassDetailFormLine > .q-pa-sm
  padding: 0px 6px !important
.noborderCard
  border-top-width: 0px !important
.cardselectarea
  .customSelect
    padding: 0px !important
    .q-field__inner
      padding: 0px !important
  .customSelect.q-field--dense .q-field__control, .customSelect.q-field--dense .q-field__marginal
    height: 26px !important
    min-height: 26px !important
.revisionLayout .customDate, .revisionLayout .customText
  padding-bottom: 20px !important
.cardClass-height-100
  height: 100px
</style>