var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("div", { staticClass: "header-btn-lg pr-0" }, [
        _c("div", { staticClass: "widget-content p-0" }, [
          _c("div", { staticClass: "widget-content-wrapper" }, [
            _c(
              "div",
              { staticClass: "widget-content-left username_top" },
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      "toggle-class": "p-0 mr-2",
                      "menu-class": "dropdown-menu-lg",
                      variant: "link",
                      "no-caret": "",
                      right: "",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: { slot: "button-content" },
                        slot: "button-content",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "widget-content-left ml-3 header-user-info",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "widget-heading text-white" },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(_vm.userName),
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "material-icons header-user-icon",
                              },
                              [_vm._v("account_circle")]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "dropdown-menu-header topUserDiv" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "dropdown-menu-header-inner bg-orange-custom",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "menu-header-content text-left" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "widget-content p-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "widget-content-wrapper" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "widget-content-left",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "text-white" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "material-icons-outlined",
                                                  },
                                                  [_vm._v("account_circle")]
                                                ),
                                                _vm._v(" "),
                                                _c("span", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.userName
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "text-white" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "material-icons-outlined",
                                                  },
                                                  [_vm._v("business")]
                                                ),
                                                _vm._v(" "),
                                                _c("span", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.userDeptName
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "widget-content-right mr-2",
                                          },
                                          [
                                            _c(
                                              "q-btn",
                                              {
                                                staticStyle: { width: "90px" },
                                                attrs: {
                                                  loading: _vm.loading,
                                                  outline: "",
                                                  rounded: "",
                                                  size: "sm",
                                                  icon: "logout",
                                                  color: "white",
                                                },
                                                on: { click: _vm.logout },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "loading",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "q-spinner-hourglass",
                                                          {
                                                            staticClass:
                                                              "on-left",
                                                          }
                                                        ),
                                                        _vm._v(" Logout... "),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ]),
                                              },
                                              [_vm._v(" Logout ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "scroll-area-xs",
                        staticStyle: { height: "40px" },
                      },
                      [
                        _c("ul", { staticClass: "nav flex-column" }, [
                          _c(
                            "li",
                            {
                              staticClass: "nav-item-header nav-item",
                              staticStyle: { cursor: "pointer" },
                              on: { click: _vm.changePassword },
                            },
                            [_vm._v(_vm._s(_vm.$language("비밀번호 변경")))]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }