/* eslint-disable */
import request from "@/utils/request";
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
import common from "@/js/common";

const systemInfo = {
  state: {
    mainUrl: "",
    depts: [],
    users: [],
    vendors: [],
    vendorUsers: [],
    isGetDepts: false,
    isGetUsers: false,
    isGetVendors: false,
    isGetVendorUsers: false,
  },
  mutations: {
    SET_DASHBOARD: (state, view) => {
      state.mainUrl = view;
    },
    SET_DEPTS: (state, view) => {
      state.depts = view;
    },
    SET_USERS: (state, view) => {
      state.users = view;
    },
    SET_VENDORS: (state, view) => {
      state.vendors = view;
    },
    SET_VENDOR_USERS: (state, view) => {
      state.vendorUsers = view;
    },
  },
  actions: {
    SetDashBorad({ commit, state }, url) {
      commit("SET_DASHBOARD", url);
    },
    GetDepts({ commit, state }, view) {
      if (state.isGetDepts) return;
      if (state.depts && state.depts.length > 0) return;
      return new Promise((resolve, reject) => {
        state.isGetDepts = true;
        getDepts()
          .then((response) => {
            commit("SET_DEPTS", response.data);

            resolve(response.data);
            state.isGetDepts = false;
          })
          .catch((error) => {
            reject(error);
            state.isGetDepts = false;
          });
      });
    },
    GetUsers({ commit, state }, view) {
      if (state.isGetUsers) return;
      if (state.users && state.users.length > 0) return;
      return new Promise((resolve, reject) => {
        state.isGetUsers = true;
        getUsers()
          .then((response) => {
            commit("SET_USERS", response.data);

            resolve(response.data);
            state.isGetUsers = false;
          })
          .catch((error) => {
            reject(error);
            state.isGetUsers = false;
          });
      });
    },
    GetVendors({ commit, state }, view) {
      if (state.isGetVendors) return;
      if (state.vendors && state.vendors.length > 0) return;
      return new Promise((resolve, reject) => {
        state.isGetVendors = true;
        getVendors()
          .then((response) => {
            commit("SET_VENDORS", response.data);

            resolve(response.data);
            state.isGetVendors = false;
          })
          .catch((error) => {
            reject(error);
            state.isGetVendors = false;
          });
      });
    },
    GetVendorUsers({ commit, state }, view) {
      if (state.isGetVendorUsers) return;
      if (state.vendorUsers && state.vendorUsers.length > 0) return;
      return new Promise((resolve, reject) => {
        state.isGetVendorUsers = true;
        getVendorUsers()
          .then((response) => {
            commit("SET_VENDOR_USERS", response.data);

            resolve(response.data);
            state.isGetVendorUsers = false;
          })
          .catch((error) => {
            reject(error);
            state.isGetVendorUsers = false;
          });
      });
    },
  },
};

export function getDepts() {
  return request({
    url: selectConfig.auth.userInfo.depts.url,
    method: "GET",
    params: {
      lang: common.getLang(),
    },
  });
}

export function getUsers() {
  return request({
    url: selectConfig.auth.userInfo.users.url,
    method: "GET",
    params: {
      lang: common.getLang(),
    },
  });
}

export function getVendors() {
  return request({
    url: selectConfig.auth.userInfo.vendors.url,
    method: "GET",
    params: {
      lang: common.getLang(),
    },
  });
}

export function getVendorUsers() {
  const param = {
    vendorCd: window.sessionStorage.getItem("defaultVendorCd"),
  };
  return request({
    url: selectConfig.mdm.cim.vendor.user.list.url,
    method: "GET",
    params: {
      lang: common.getLang(),
    },
  });
}

export default systemInfo;
