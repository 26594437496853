<template>
  <div class="topTabs">
    <q-tabs
      v-model="topTab"
      dense
      inline-label
      outside-arrows
      mobile-arrows
      align="left"
      class="tabmenu-bg"
    >
      <draggable
        :list="visitedViews"
        class="topTab-drag"
        ghost-class="topTab-drag-ghost"
      >
        <q-tab
          v-for="tag in visitedViews"
          ref="tag"
          :key="tag.path"
          :name="tag.path"
          :label="tag.title"
          :class="getFavoriteClass(tag.name)"
          v-on:click="movePath(tag.path)">
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            touch-position
            context-menu
          >
            <q-list dense class="topTabContext">
              <q-item clickable v-close-popup v-on:click="allCloseTab">
                <q-item-section><q-icon class="text-dark" name="cancel" /> {{$language('전체닫기')}} </q-item-section>
              </q-item>
              <q-item clickable v-close-popup v-on:click="OtherCloseTab(tag)">
                <q-item-section><q-icon class="text-dark" name="highlight_off" /> {{$language('다른탭닫기')}} </q-item-section>
              </q-item>
              <q-item clickable v-close-popup v-on:click="addFavoriteTab(tag)">
                <q-item-section><q-icon class="text-dark" name="star" /> {{setFavoriteLabel(tag)}} </q-item-section>
              </q-item>
              <q-item clickable v-close-popup v-on:click="openNewWindow(tag)" v-if="tag.fullPath != '/main/index' && tag.fullPath != '/main/indexDirector'">
                <q-item-section><q-icon class="text-dark" name="launch" /> {{$language('새창으로 열기')}} </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
          <q-icon class="deltab3 text-default-psm-custom" name="bug_report" @click="openDefect(tag)" v-if="isDefectManage()">
            <q-tooltip>{{$language('결함등록')}}</q-tooltip>
          </q-icon>
          <q-icon class="deltab2 text-orange-custom" name="quiz" @click="openManual(tag)" v-if="tag.meta.manual">
            <q-tooltip>{{$language('매뉴얼 보기')}}</q-tooltip>
          </q-icon>
          <q-icon class="deltab text-default-psm-custom" name="close" @click="closeTag(tag)">
            <q-tooltip>{{$language('닫기')}}</q-tooltip><!-- // 에스엘용 수정 text-indigo / 원본 text-teal  -->
          </q-icon>
        </q-tab>
      </draggable>
    </q-tabs>
  </div>
</template>

<script>

import transactionConfig from '@/js/transactionConfig';
import draggable from 'vuedraggable';
export default {
  components: {
    draggable,
  },

  data() {
    return {
      topTab: 'menus',
      selectedTag: {}
    };
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
  },
  watch: {
    $route() {
      this.addViewTags();
      this.topTab = this.$route.path
    },
    topTab() {
      if (this.topTab !== this.$route.path) {
        this.topTab = this.$route.path
      }
    },
  },
  mounted() {
    this.addViewTags();
    this.topTab = this.$route.path
  },
  methods: {
    isDefectManage() {
      return this.$store.getters.user.defectManageFlag === 'Y' ? true : false;
    },
    getFavoriteClass(_sysMenuId) {
      if (this.$store.state.app.favorites.findIndex(i => i.sysMenuId === _sysMenuId) > -1) {
        return 'favoritePageTab';
      } else {
        // return 'favoritePageTabNone';
        return '';
      }
    },
    addViewTags() {
      const { name } = this.$route;
      if (name) {
        this.$store.dispatch('addView', this.$route);
      }
      return false;
    },
    isActive(route) {
      return route.path === this.$route.path;
    },
    movePath(path) {
      if (!this.isActive({path: path})) {
        this.$comm.movePage(this.$router, path)
      }
    },
    closeTag(tag) {
      // this.topTab = null
      this.$store.dispatch('delView', tag).then(({ visitedViews }) => {
        if (this.isActive(tag)) {
          const latestView = visitedViews.slice(0)[0];
          if (latestView) {
            this.$router.push(latestView);
          } else {
            this.$router.push('/');
          }
        }
      });
    },
    openManual(tag) {
      window.open('/sys/menu/manualPage?sysMenuId='+tag.name+'','manualpop','width=1120, height=768, menubar=no, status=no, toolbar=no');
    },
    openDefect(tag) {
      window.open('/sys/defect/defectManage?sysMenuId='+tag.name+'','manualpop','width='+(window.innerWidth-200)+', height=768, menubar=no, status=no, toolbar=no');
    },
    allCloseTab() {
      this.$store.dispatch('delAllViews');
      this.$router.push('/');
    },
    OtherCloseTab(tag) {
      if (this.$route.path !== tag.path) {
        this.$router.push(tag);
      }
      this.$store.dispatch('delOthersViews', tag).then(() => {
        this.topTab = this.$route.path
      });
    },
    setFavoriteLabel(tag) {
      if (this.$store.state.app.favorites.findIndex(i => i.sysMenuId === tag.name) < 0) {
        return this.$language('즐겨찾기 추가');
      } else {
        return this.$language('즐겨찾기 삭제'); 
      }
      
    },
    addFavoriteTab(tag) {
      this.$http.url = transactionConfig.sys.favorite.insert.url;
      if (this.$store.state.app.favorites.findIndex(i => i.sysMenuId === tag.name) < 0) {
        this.$http.type = 'POST';
      } else {
        this.$http.type = 'PUT';
      }
      this.$http.param = {
        userId: this.$store.getters.user.userId,
        sysMenuId: tag.name,
        sortOrder: this.$store.state.app.favorites.length + 1
      };
      this.$http.request(() => {
        this.$store.dispatch('GetFavorites', {userId: this.$store.getters.user.userId});
      });
    },
    openNewWindow(tag) {
      window.open(tag.fullPath);
    }
  }
};
</script>