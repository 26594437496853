<template>
  <div class="fix-height">
    <q-field
      v-show="false"
      ref="customDatepicker"
      class="customDate"
      :class="[beforeText ? 'before-field' : '']"
      :dense="dense"
      filled
      stack-label
      color="orange-custom"
      :label="convertLabel"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      v-on:click.self.prevent="click"
      @focus="click"
      @blur="blur"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        {{valueText}}
      </template>

      <template v-slot:before>
        <template v-if="beforeText">
          <div class="custom-text-before q-pl-xs q-pr-xs bg-blue-7 text-white">
            {{beforeText}}
          </div>
        </template>
      </template>

      <template v-slot:append>
        <q-icon v-if="editable&&!disabled" name="close" @click.self.prevent="reset" class="cursor-pointer dateCloseIcon"></q-icon>
      </template>
    </q-field>

      <!-- :open="isOpen" -->
    <div class="row items-center all-pointer-events customdatelabel" v-if="label">
      <b>
        <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
        <font class="formLabelTitle-2">{{convertLabel}}</font>
        <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
      </b>
    </div>
    <datetime-picker
      v-model="vValue"
      :width="datepickerWidth"
      :lang="krLanguage"
      :range="range"
      :type="type"
      :format="format"
      :value-type="format"
      :not-before="start"
      :not-after="end"
      :disabled-date="disabledDate"
      :clearable="clearable"
      :append-to-body="appendToBody"
      class="cdatetimepic"
      input-class="inputClass"
      popup-class="datepopupClass"
      :confirm="isConfirm"
      confirmText="ok"
      :minute-step="minuteStep"
      :time-picker-options="timePickerOptions"
      :hourOptions="hourOptions"
      :minuteOptions="minuteOptions"
      :show-time-header="true"
      :time-title-format="timeTitleFormat"
      :disabled="disabled || !editable"
      @input="input"
      @change="change"
      @confirm="confirm"
      @calendar-change="calendarChange"
      @panel-change="panelChange"
    >
      <template v-slot:header v-if="range && type=='date'">
        <q-btn-group outline >
          <button type="button" class="cal-btn-header" @click="dateMovePrevYear">{{ $language('작년') }}</button>
          <button type="button" class="cal-btn-header" @click="dateMove(-12)">-1Y</button>
          <button type="button" class="cal-btn-header" @click="dateMove(-6)">-6M</button>
          <button type="button" class="cal-btn-header" @click="dateMove(-3)">-3M</button>
          <button type="button" class="cal-btn-header" @click="dateMove(-1)">-1M</button>
          <button type="button" class="cal-btn-header" @click="dateMove(0)">Today</button>
          <button type="button" class="cal-btn-header" @click="dateMoveRange(1)">-1M~+1M</button>
          <button type="button" class="cal-btn-header" @click="dateMoveRange(3)">-3M~+3M</button>
          <button type="button" class="cal-btn-header" @click="dateMoveThisYear">{{ $language('올해') }}</button>
        </q-btn-group>
      </template>
    </datetime-picker>
  </div>
</template>

<script>
import mixinCommon from './js/mixin-common'
import vue2Datepicker from 'vue2-datepicker';
import ko from 'vue2-datepicker/locale/ko';
import en from 'vue2-datepicker/locale/en';
import ja from 'vue2-datepicker/locale/ja';
import zh_cn from 'vue2-datepicker/locale/zh-cn';
import zh_tw from 'vue2-datepicker/locale/zh-tw';

// require("moment/min/locales.min");
export default {
  /* attributes: name, components, props, data */
  name: 'c-datepicker',
  mixins: [mixinCommon],
  components: {
    'datetime-picker': vue2Datepicker,
  },
  props: {
    name: {
      type: String,
      default: 'date',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: [String, Date, Array],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    // 기간 선택 여부
    range: {
      type: Boolean,
      default: false,
    },
    // 기본:날짜 선택, month: 월만 선택, year: 년도만 선택, time: 시간 선택
    type: {
      type: String,
      default: 'date',
    },
    minuteStep: {
      type: Number,
      default: 0,
    },
    // 날짜의 기본값
    default: {
      type: [String, Array],
      default: null,
    },
    defaultPeriod: {
      type: String,
      default: '1m',
    },
    placeholder: {
      type: String,
      default: '',
    },
    datepickerWidth: {
      type: [Number, String],
      default: '100%',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    // 시작일
    start: {
      type: String,
      default: '',
    },
    // 종료일
    end: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    timePickerOptions: {
      type: Object,
    },
    hourOptions: {
      type: Array,
    },
    minuteOptions: {
      type: Array,
    },
    viewFormat: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: true,
    },
    beforeText: {
      type: String,
      default: '',
    },
    defaultStart: {
      type: String,
      default: '',
    },
    defaultEnd: {
      type: String,
      default: '',
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localFormattedDate: null, // TODO : 날짜 값을 표시하는 v-text-field에 표시되는 값으로 local date format 형태의 값(언어 설정 변경시 같이 변경됨)
      locale: 'ko-KR', // window.getApp.locale.datepicker
      period: [],
      vValue: null, // TODO : datepicker 내부 값, YYYY-MM-DD(ISO 표준) 형태의 값
      /** kdh */
      isOpen: false,
      valueText: '',
      timer: null,
    };
  },
  computed: {
    isConfirm() {
      return this.range || this.type === 'time' || this.type === 'datetime'
    },
    format() {
      if (this.viewFormat) {
        return this.viewFormat;
      }

      let returnText = 'YYYY-MM-DD';

      if (this.type === 'month') {
        returnText = 'YYYY-MM';
      } else if (this.type === 'year') {
        returnText = 'YYYY';
      } else if (this.type === 'time') {
        if (this.minuteStep > 0) {
          returnText = 'HH:mm';
        } else {
          returnText = 'HH:mm:ss';
        }
      } else if (this.type === 'datetime') {
        if (this.minuteStep > 0) {
          returnText = 'YYYY-MM-DD HH:mm';
        } else {
          returnText = 'YYYY-MM-DD HH:mm:ss';
        }
      } else {
        returnText = 'YYYY-MM-DD';
      }

      return returnText;
    },
    timeTitleFormat() {
      let returnText = 'HH:mm';
      if (this.type === 'datetime' || this.type === 'date') {
        if (this.minuteStep > 0) {
          if (this.$comm.getLang() === 'kr') {
            returnText = 'HH시mm분'
          }
        } else {
          if (this.$comm.getLang() === 'kr') {
            returnText = 'HH시mm분s초'
          } else {
            returnText = 'HH:mm:s'
          }
        }
      }
      return returnText;
    },
    krLanguage() {
      let lang = ko;
      if (this.$comm.getLang() === 'en') {
        lang = en;
      } else if (this.$comm.getLang() === 'ja') {
        lang = ja;
      } else if (this.$comm.getLang() === 'zh_cn') {
        lang = zh_cn;
      } else if (this.$comm.getLang() === 'zh_tw') {
        lang = zh_tw;
      }
      return lang;
    },
  },
  // etg 2024-10-07 default 제거
  watch: {
    default() {
      // this.setDefault();
    },
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
    defaultStart() {
      // this.setDefault();
    },
    defaultEnd() {
      // this.setDefault();
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.setValueText();
    // this.setDefault();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  /* methods */
  methods: {
    input() {
      this.setValueText();
      if (this.range && this.vValue && this.vValue.length === 2 && !this.vValue[0]) {
        this.vValue = null;
      }
      this.$emit('input', this.vValue);
      this.$emit('datachange', this.vValue);
    },
    setValueText() {
      if (!this.range) {
        this.valueText = this.$_.clone(this.vValue);
      } else {
        if (this.vValue && this.vValue.length === 2 && this.vValue[0]) {
          this.valueText = this.vValue[0] + ' ~ ' + this.vValue[1]
        } else {
          this.valueText = '';
        }
      }
    },
    change() {
      if (!this.isConfirm) {
        this.isOpen = false;
        // 값 변경 이벤트 :: input method에서 change emit
        // this.$emit('datachange', this.vValue);
      }
    },
    disabledDate(date) {
      let startCheck = false;
      let endCheck = false;
      if (this.start) {
        startCheck = date < this.$comm.moment(this.start);
      }
      if (this.end) {
        endCheck = date > this.$comm.moment(this.end);
      }
      return startCheck || endCheck
    },
    reset() {
      this.valueText = '';
      this.vValue = null;
      this.$emit('input', this.vValue);
    },
    click() {
      if (this.editable && !this.disabled) {
        this.isOpen = true;
      }
    },
    confirm() {
      this.isOpen = false;
      // this.$emit('datachange', this.vValue);
    },
    blur() {
      if (!this.isConfirm) {
        this.timer = setTimeout(() => {
          this.isOpen = false;
        }, 200);
      }
    },
    /* eslint-disable no-unused-vars */
    calendarChange(date, oldDate, type, oldType) {
      clearTimeout(this.timer);
    },
    panelChange(type, oldType) {
      clearTimeout(this.timer);
    },
    setDefault() {
      if (!this.value || (this.value && this.value.length === 0)) {
        // 범위인 경우 defaultStart, defaultEnd 값이 들어온 경우에 한에서 처리
        if (this.range && this.defaultStart && this.defaultEnd && (!this.vValue || this.vValue.length === 0)) {
          let typeList = ['y', 'M', 'd'];
          let start = {
            type: '',
            num: 0,
          };
          let end = {
            type: '',
            num: 0,
          };
          this.$_.forEach(typeList, item => {
            if (this.$_.indexOf(this.defaultStart, item) > -1) {
              start.type = item;
              start.num = Number(this.$_.replace(this.defaultStart, start.type, ''))
            }
            if (this.$_.indexOf(this.defaultEnd, item) > -1) {
              end.type = item;
              end.num = Number(this.$_.replace(this.defaultEnd, end.type, ''))
            }
          });

          // 기간 초기설정값 해제(전체용-프로젝트 시 주석제거)
          this.$emit('input', [
            this.defaultStart === 'startOfYear' ? this.$comm.getFirstDayThisYear() : this.$comm.moment().add(start.num, start.type).format(this.format),
            this.$comm.moment().add(end.num, end.type).format(this.format),
          ]);
        }

        if (!this.default) return;
        
        // 기간 초기설정값 해제(전체용-프로젝트 시 주석제거)
        if (this.default === 'today') {
          if (this.range) {
            this.$emit('input', [
              this.$comm.moment().format(this.format),
              this.$comm.moment().format(this.format)
            ]);
          } else {
            this.$emit(
              'input',
              this.$comm.moment().format(this.format)
            );
          }
        }
      }
    },
    dateMove(_mon) {
      if (_mon == 0) {
        this.$emit('input', [this.$comm.getToday(),this.$comm.getToday()]);
      } else if (_mon > 0) {
        this.$emit('input', [this.$comm.getToday(),this.$comm.getAddMonth(this.$comm.getToday(), _mon)]);
      } else if (_mon < 0) {
        this.$emit('input', [this.$comm.getAddMonth(this.$comm.getToday(), _mon), this.$comm.getToday()]);
      }
    },
    dateMoveRange(_mon) {
      this.$emit('input', [this.$comm.getAddMonth(this.$comm.getToday(), _mon * -1), this.$comm.getAddMonth(this.$comm.getToday(), _mon * 1)]);
    },
    dateMoveThisYear() {
      this.$emit('input', [this.$comm.getFirstDayThisYear(), this.$comm.getLastDayThisYear()]);
    },
    dateMovePrevYear() {
      this.$emit('input', [this.$comm.getPrevYear(1) + '-01-01', this.$comm.getPrevYear(1) + '-12-31']);
    }
  },
};
</script>

<style>
::-webkit-input-placeholder {
  font-size: 0.8rem;
}
:-moz-placeholder {
  font-size: 0.8rem;
}
::-moz-placeholder {
  font-size: 0.8rem;
}
:-ms-input-placeholder {
  font-size: 0.8rem;
}
</style>

<style lang="scss">
.mx-datepicker {
  width: 100% !important;
}
.inputClass {
  width: 100% !important;
  padding: 0px 3px 0px 8px  !important;
}
.mx-datepicker-header {
  text-align: center;
}
.cal-btn-header {
  box-sizing: border-box;
  line-height: 1;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 11px;
  margin: 0;
  cursor: pointer;
  background-color: #dfdddd;
  outline: none;
  border: 0px solid rgba(0,0,0,.1);
  border-radius: 8px;
  color: #7c7b7b;
  white-space: nowrap;
}
.cal-btn-header:hover {
  background-color: #8b8a8a;
  color: #fff;
}
</style>

<style lang="sass">
.customDate
  .q-field__native.row
    padding-left: 3px
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 2px !important
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
  .q-field__before
    padding-top: 5px
.customDate:hover
  .dateCloseIcon
    display: block
.customDate.q-field--dense .q-field__control, .customDate.q-field--dense .q-field__marginal
  height: min-content !important
  min-height: 31px !important
.customDate.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
.customDate.q-field--error ~ .cdatetimepic > .mx-input-wrapper > .inputClass
  border: 1px solid rgba(0,0,0,0.15) !important
  border-bottom: 2px solid #c10015 !important
.main-header-input2 .cdatetimepic .mx-input-wrapper .inputClass
  border-width:0px !important
  text-align: right !important
  padding-right: 30px !important
  background: #f8f8f8 !important

.customdatelabel
  margin-top: -20px !important
  padding-left: 10px
  padding-bottom: 1px !important
.cdatetimepic .mx-input-wrapper
  padding: 0px 3px
  margin-bottom: 24px
  .inputClass
    border-radius: 3px 3px 0 0
    height: 33px
    border: 1px solid rgba(0,0,0,0.15) !important
  .inputClass:disabled
    border: 1px solid rgba(0, 0, 0, 0.45) !important
    background: #F8F9FA !important
    color: rgba(0,0,0,1) !important
    opacity: 1 !important
.customdatelabel .searchAreaLabelIcon
  font-size: 10px !important
  font-weight: bold
// .customdatelabel .labelfrontIcon
//   color: #2d353c !important

.formLabelTitle-2
  font-size: 0.9em !important
  color: #0072c6 !important

.custom-text-before
  font-size:14px
  border: 1px solid rgba(0, 0, 0, 0.15) !important
  border-right: 0  !important
  border-radius: 4px 0 0 0
  line-height: 31px
  height: 33px !important
  min-height: 33px !important
.tableDatepicker
  .customdatelabel
    margin-top: 0px !important
  .cdatetimepic .mx-input-wrapper
    padding: 0px 3px
    margin-bottom: 0px !important
    .inputClass
      padding: 0px 3px  !important
      border-radius: 3px 3px 0 0
      height: 28px
      border: 1px solid rgba(0,0,0,0.15) !important
    .inputClass:disabled
      padding: 0px 3px  !important
      border: 1px solid rgba(0, 0, 0, 0.45) !important
      background: #F8F9FA !important

.main-header-input2 .cdatetimepic .mx-input-wrapper
  margin-bottom: 0px !important
.main-header-input2 .customdatelabel
  margin-top: 0px !important
</style>