<template>
  <div class="fix-height row excel-upload-cover">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <q-uploader
        flat bordered with-credentials
        ref="excel-upload"
        class="excel-upload"
        capture="environment"
        field-name="file" 
        method="POST"
        :label="convertLabel"
        :auto-upload="false"
        :hide-upload-btn="true"
        :multiple="false"
        accept="xlsx"
        :max-file-size="100000000"
        :max-total-size="100000000"
        :max-files="1"
        :no-thumbnails="false"
        :disable="!editable"
        :style="maxheight"
        @rejected="rejected"
        @added="added"
      >
        <!-- :filter="filter" -->
        <template v-slot:header="scope">
          <div class="row no-wrap items-center q-pa-none q-gutter-xs">
            <q-spinner v-if="scope.isUploading" class="q-uploader__spinner" />
            <div class="col" style="padding-top: 0px !important;margin-top: 0px !important;">
              <div class="q-uploader__title inline-block" style="padding: 4px 0px !important;font-size: 0.95em !important">{{convertLabel}}</div>&nbsp;&nbsp;
              <div class="q-uploader__subtitle inline-block" v-if="editable">{{ scope.uploadSizeLabel }} / {{ scope.uploadProgressLabel }}</div>
            </div>
            <q-btn
              v-if="editable && isTemplateDown"  
              class="excel-upload-btn"
              icon="file_download"
              dense outline 
              size="sm"
              :label="$language('양식 다운로드')"
              @click="downTemplate">
            </q-btn>
            <q-btn v-if="scope.queuedFiles.length > 0&&editable"
              class="excel-upload-btn"
              icon="delete"
              dense outline 
              size="sm"
              :label="$language('파일 삭제')"
              @click="deleteFile(scope)">
            </q-btn>
          </div>
        </template>
        <template v-slot:list="scope">
          <template v-if="scope.files && scope.files.length > 0">
            <q-list separator>
              <q-item v-for="file in scope.files" :key="file.name">
                <q-item-section>
                  <q-item-label class="full-width ellipsis">
                    {{ file.name }}
                  </q-item-label>

                  <q-item-label caption>
                    Status: {{ file.__status }}
                  </q-item-label>

                  <q-item-label caption>
                    {{ file.__sizeLabel }} / {{ file.__progressLabel }}
                  </q-item-label>
                </q-item-section>

                <q-item-section
                  v-if="file.__img"
                  thumbnail
                  class="gt-xs"
                >
                  <img :src="file.__img.src">
                </q-item-section>
              </q-item>
            </q-list>
          </template>
          <template v-else>
            <div class="empty_dscription">
              <div style="text-align: center; padding-bottom:5px" v-show="editable">
                <q-chip outline square icon="info" style="width:100%">
                  Drag or Click
                </q-chip>
              </div>
              <div class="q-pl-md txtfileinfo" style="text-align: left">
                <span class="text-positive">
                  {{$language('업로드 가능 확장자')}}
                </span>
                : xlsx
                <br/>
                <span class="text-positive">
                  {{$language('업로드 가능 파일 수')}}
                </span>
                : 1
                <br/>
                <span class="text-positive">
                  {{$language('허용 파일 크기')}}
                </span>
                : 100MB
              </div>
              <q-uploader-add-trigger />
            </div>
          </template>
        </template>
      </q-uploader>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
      <c-card title="업로드 현황" class="cardClassDetailForm excel-upload-status-card" height="150px">
        <template slot="card-detail">
          <div class="col-12">
            <span v-html="convertEnter(message)"></span>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-12">
      <c-tab
        type="tabcard"
        align="left"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        :height="tabHeight"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <div class="check-up-loading-style">
            <c-table
              :ref="'table' + tab.name"
              title="Excel Sheet별 결과 목록"
              tableId="excel-upload"
              :editable="editable"
              :columns="excelUploadInfo.columns[Number(tab.name)]"
              :data="excelUploadInfo.data[Number(tab.name)] ? excelUploadInfo.data[Number(tab.name)].filter(data => !errorDataCheck || data.error_message) : []"
              :gridHeight="gridHeight"
              :isExpand="true"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              selection="multiple"
              :rowKey="excelUploadInfo.rowKeys[Number(tab.name)]"
              :customTrClass="setTrClass"
            >
              <template slot="table-button">
                <q-btn-group outline >
                  <c-btn v-if="excelUploadInfo.data[Number(tab.name)] && excelUploadInfo.data[Number(tab.name)].length > 0 && editable" 
                    label="제외" 
                    icon="remove" 
                    @btnClicked="removeData(tab.name)" />
                </q-btn-group>
              </template>
              <template v-slot:expand-header>
                {{$language('에러 상세')}}
              </template>
              <template v-slot:expand-td="props">
                <c-card
                  class="excel-upload-status-card"
                  :noHeader="true">
                  <template slot="card-detail">
                    <div class="col-12">
                      <span v-html="convertEnter(props.row.error_message)"></span>
                    </div>
                  </template>
                </c-card>
              </template>
            </c-table>
            <q-inner-loading v-if="firstShow || secondShow"
              style="z-index: 100;"
              :showing="visible"
            >
              <template slot="default">
                <div v-if="firstShow"
                  class="check-up-loading-style-text" 
                  data-text="양식에 맞게 업로드 하세요.">
                  {{ $language('양식에 맞게 업로드 하세요.') }}
                </div>
              </template>
            </q-inner-loading>
            <q-inner-loading v-else-if="secondShow"
              style="z-index: 100;"
              :showing="visible"
            >
              <template slot="default">
                <div class="check-up-loading-style-text" 
                  data-text="파일을 읽는 중입니다.">
                  {{ $language('파일을 읽는 중입니다.') }}
                </div>
              </template>
            </q-inner-loading>
            <q-inner-loading v-else-if="thirdShow"
              style="z-index: 100;"
              :showing="visible"
            >
              <template slot="default">
                <q-spinner-clock
                  class="check-up-spinner"
                  size="5.5em"
                />
                <div class="check-up-loading-style-text check-up-animation" 
                  data-text="업로드 중입니다. . . .">
                  {{ $language('업로드 중입니다.') }} . . . 
                </div>
                <div class="check-up-loading-style-text">
                  <ICountUp :startVal="0" :endVal="ingCnt" :decimals="0"
                    :duration="4" :options="countOptions" />
                </div>
              </template>
            </q-inner-loading>
            <q-inner-loading v-else-if="fourthShow"
              style="z-index: 100;"
              :showing="visible"
            >
              <template slot="default">
                <i :class="['material-icons', 'thumbIcon']"
                  style="font-size:5.5em;color:#3498db">
                  thumb_up
                </i>
                <div class="check-up-loading-style-text" 
                  style="color:#3498db;">
                  {{ $language('성공적으로 업로드 되었습니다. 적용버튼을 누르세요.') }}
                </div>
              </template>
            </q-inner-loading>
            <q-inner-loading v-else :showing="visible" >
              <template slot="default">
                <q-spinner-comment class="check-up-spinner" size="5.5em" />
                <div class="check-up-loading-style-text" 
                  v-html="failMessage">
                </div>
              </template>
            </q-inner-loading>
          </div>
        </template>
      </c-tab>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { uid } from 'quasar';
import XLSX from 'xlsx'
import ICountUp from "vue-countup-v2";
import selectConfig from '@/js/selectConfig';
import mixinCommon from './js/mixin-common'
import transactionConfig from '../js/transactionConfig';
export default {
  /* attributes: name, components, props, data */
  name: 'c-check-up-opinion-excel-upload',
  components: {
    ICountUp,
  },
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    editable: { // 쓰기 권한 여부
      type: Boolean,
      default: true,
    },
    isTemplateDown: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '엑셀 업로드', 
    },
    maxheight: {
      type: String,
      default: 'max-height:200px;min-height:200px;width: 100%;',
    },
    tabHeight: {
      type: String,
      default: '430px',
    },
    gridHeight: {
      type: String,
      default: '400px',
    },
    hospitalId: {
      type: String,
      default: null,
    },
    heaCheckupPlanId: {
      type: String,
      default: null
    },
    excelUploadInfo: {
      type: Object,
      default: function() {
        return {
          rowKeys: [], // 데이터들의 키 속성값
          taskClassCd: '', // 엑셀업로드 양식 구분 값
          sheetNum: 0,
          columns: [],
          data: [],
        }
      }
    },
  },
  data() {
    return {
      errorDataCheck: false,
      fileInfo: {
        oriFileNm: '',
        fileExt: '',
      },
      uploadIndex: 0,
      firstShow: true,
      secondShow: false,
      thirdShow: false,
      fourthShow: false,
      failMessage: '',
      visible: true,
      isUpload: false,
      // userList: [],
      isError: false,
      message: '',
      templateHeader: [],
      sheetNames: [],
      tab: 0,
      fileInfoUrl: '',
      templateExcelFileUrl: '',
      listUrl: '',
      checkupList: [],
      checkUpNameList: [],
      checkUpIdList: [],
      nextIdx: 0,
      widthLongName: '',
      countOptions: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "건 / 300건"
      },
      ingCnt: 0,
      tempSaveUrl: '',
      tempDeleteUrl: '',
      tempListUrl: '',
      saveDataList: [],
      jsonData: [],
      columnIdx: 0,
      seqCount: 0,
      noUserCnt: 0,
      retireUserCnt: 0,
      noUsers: '',
      retireUsers: '',
      cfb: {},
      longNames: [],
      midNames: [],
      fileName: '',
      oriFileName: '',
      fileInfo2: {},
    };
  },
  computed: {
    tabItems() {
      let _items = [];
      if (this.sheetNames && this.sheetNames.length > 0) {
        let idx = 0;
        this.$_.forEach(this.sheetNames, sheet => {
          _items.push({
            name: idx, 
            label: sheet
          })
          idx++;
        });
      }
      return _items;
    },
    truncateSize() {
      return 100;
    }
  },
  watch: {
    message() {
      /**
       * 오류 메시지가 있는 경우 Excel 결과 목록에 표시 X
       * 업로더에 올라간 파일로 reset
       */
      if (this.isError) {
        this.excelUploadInfo.data = [];
        this.$refs['excel-upload'].reset();
      }
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      // url setting
      this.listUrl = selectConfig.hea.examine.list.url;
      this.templateExcelFileUrl = selectConfig.sys.excelUpload.template.url;
      this.fileInfoUrl = selectConfig.com.upload.fileInfo.url
      this.tempSaveUrl = transactionConfig.hea.examine.tempUploadSave.url;
      this.tempDeleteUrl = transactionConfig.hea.examine.tempUploadDelete.url;
      this.tempListUrl = selectConfig.hea.examine.tempUploadList.url;
      // this.tempExamineCompareUrl = selectConfig.hea.opinion.tempExamineCompareList.url;
      this.longNames = ['소견텍스트', '소견 세부결과', '사후관리소견'];
      this.midNames = ['유해인자', '검진소견', '비고']
      this.$set(this.excelUploadInfo, 'columns', [
        [
          {
            name: 'deptName',
            field: 'deptName',
            label: '소속',
            align: 'center',
            isExamine: false,
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            align: 'center',
            style: 'width:100px',
            isExamine: false,
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            isExamine: false,
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            isExamine: false,
          },
          {
            name: 'checkupDate',
            field: 'checkupDate',
            label: '검진일자',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            isExamine: false,
            // type: 'date',
            // format: 'YYYY-MM-DD',
            // excelFormat: 'YYYY/MM/DD',
          },
        ]
      ])
      // list
      this.getCheckUpItemList();
      this.getFileInfo();
      this.getExcelTemplateHeader();
    },
    getCheckUpItemList() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y'
      };
      this.$http.request((_result) => {
        this.checkupList = _result.data;
      },);
    },
    getFileInfo() {
      this.$http.url = this.fileInfoUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        taskClassCd: 'EXCEL_UPLOAD_TEMPLATE',
        taskKey: this.excelUploadInfo.taskClassCd,
      };
      this.$http.request((_result) => {
        this.fileInfo = _result.data;
      },);
    },
    getFileSizeTextByRound(_fileByteSize) {
      return this.$comm.bytesToSize(_fileByteSize);
    },
    added(files) {
      this.fileName = files[0].name
      this.fileInfo2 = {
        fileName: this.fileName,
        connectId: this.heaCheckupPlanId,
        regUserId: this.$store.getters.user.userId
      }
      this.$http.url = selectConfig.sys.excelUpload.checkName.url
      this.$http.type = 'GET';
      this.$http.param = this.fileInfo2
      this.$http.request((_result) => {
        if (_result.data > 0) {
          this.failMessage = '파일명이 중복된 파일을 업로드하였습니다.'
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '파일명이 중복된 파일을 업로드하였습니다.',
            type: 'warning', // success / info / warning / error
          });
          return // 중복이므로 이후 코드를 실행하지 않고 함수 종료
        } else {
          // 중복이 아닐 경우에만 다음 로직 실행
          this.processUpload(files);
        }
      });
    },
    processUpload(files) {
      this.$emit('noUpload', true)
      this.isUpload = true;
      this.ingCnt = 0;
      this.visible = true;
      this.failMessage = ''
      this.firstShow = false;
      this.secondShow = true;
      this.thirdShow = false;
      this.fourthShow = false;

      this.isError = false;
      this.message = '';
      this.excelUploadInfo.data = [];
      // 엑셀의 헤더가 업로드 된 엑셀과 맞지 않을 경우를 체크
      let thisVue = this;
      let reader = new FileReader(); 
      reader.readAsDataURL(files[0]); // 첫 번째 파일을 데이터 URL로 읽음
      reader.onload = function () { 
        let result = reader.result; // 파일의 결과를 저장
        let base64Data = result.replace(('data:' + files[0].type + ';base64,'), '') // base64 데이터에서 메타정보 제거
        thisVue.cfb = XLSX.read(base64Data, { 
          type: 'base64' 
          ,cellDates: true // 날짜 셀 처리 (false > 날짜값 문자열로 읽어옴)
        });
        let sheetNum = thisVue.excelUploadInfo.sheetNum || 0 // 시트 수를 가져옴, 기본값은 0
        for (let i = 0; i <= sheetNum; i++) {
          let excelHeader = thisVue.$comm.getHeaders(thisVue.cfb.Sheets[thisVue.cfb.SheetNames[i]]) // 엑셀 헤더 가져오기
          thisVue.jsonData = XLSX.utils.sheet_to_json(thisVue.cfb.Sheets[thisVue.cfb.SheetNames[i]], { raw: false, cellDates: true }) // 엑셀시트의 데이터를 JSON 으로 변환
          let uploadExcel = {
            header: excelHeader, 
            data: thisVue.jsonData,
          }
          // 1. 헤더가 맞지 않은 경우 체크 () > templateHeader = 기본양식다운로드용 엑셀파일의 헤더에서 가져온 임시헤더
          let headerCheck = thisVue.templateHeader[i].every((item, idx) => {
            return uploadExcel.header[idx] === item // 헤더가 일치하는지 확인 (일치하면 true, 불일치시 false 리턴)
          }) 
          if (!headerCheck) {
            thisVue.isError = true;
            thisVue.message += thisVue.$language('업로드한 엑셀의 헤더가 올바르지 않습니다. 양식 다운로드하여 확인바랍니다.')
            thisVue.emitUpload();
            break;
          }

          // 2. 데이터가 없는 경우 체크
          if (!uploadExcel.data || uploadExcel.data.length === 0) {
            thisVue.isError = true;
            thisVue.message += thisVue.$language('업로드한 엑셀에 데이터가 없습니다. 확인 후 업로드 해주십시오.')
            thisVue.emitUpload();
            break;
          }
          // 검진항목 넣는 곳
          let count = 0; 
          thisVue.$_.forEach(excelHeader, header => { // 업로드한 엑셀파일의 각 헤더 반복
            let idx = thisVue.$_.findIndex(thisVue.checkupList, {examineName: header}); // 검진항목리스트(마스터)에서 엑셀헤더명과 일치한 인덱스찾기
            let findData = null;
            if (idx > -1 && count > 4) {
              findData = thisVue.checkupList[idx]
              thisVue.$_.forEach(thisVue.jsonData, obj => {
                thisVue.renameKey(obj, header, findData.examineName) // 키 이름 변경 (업로드엑셀파일의 헤더값과 마스터헤더값이 불일치할경우 마스터헤더값으로 변경)
              })
              thisVue.excelUploadInfo.columns[i].push(
                {
                  name: findData.examineId,
                  field: findData.examineId,
                  label: findData.examineName,
                  roCd: findData.roCd,
                  isExamine: true,
                  align: thisVue.$_.includes(thisVue.longNames, findData.examineName) || 
                          thisVue.$_.includes(thisVue.midNames, findData.examineName) 
                            ? 'left' : 'center',
                  style: thisVue.$_.includes(thisVue.longNames, findData.examineName) 
                          ? 'width:1000px' 
                          : thisVue.$_.includes(thisVue.midNames, findData.examineName) 
                            ? 'width:200px' 
                            : 'width:100px',
                },
              )
            }
            count ++;
          })  
          // 업로드 한 파일 데이터 모두 저장
          this.columnIdx = i;
          thisVue.saveData(); 
        }
      }
    },
    saveData() {
      this.seqCount = 0;
      this.uploadIndex = 0;
      let promises = [];
      this.$set(this.countOptions, 'suffix', `건 / ${this.jsonData.length}건`)
      let quotient = Math.ceil(this.jsonData.length / this.truncateSize) // 100으로 나눈 몫
      let remainder = this.jsonData.length % this.truncateSize // 100으로 나눈 나머지
      for (let j = 0; j < quotient; j++) { promises.push({func: this.saveDataDetail}) } // 몫 횟수만큼 프로미스 배열에 saveDetail 함수 추가
      promises.push(
        { func: this.validCheck },
        { func: this.completeUpload },
      ) // 데이터 유효성 검사와 업로드 완료 함수를 추가합니다.
      this.$comm.orderedPromise(promises); // 프로미스를 순서대로 실행
    },
    saveDataDetail() {
      let sliceRows = this.$_.slice(this.jsonData, (this.uploadIndex * this.truncateSize), this.truncateSize * (this.uploadIndex + 1));
      return new Promise((resolve, _reject) => {
        let seq = 'HCU';
        let saveRows = [];
        this.firstShow = false;
        this.secondShow = false;
        this.thirdShow = true;
        this.fourthShow = false;

        this.$_.forEach(sliceRows, row => {
          let resultRows = []
          this.$_.forEach(Object.keys(row), _examineName => {
            let idx1 = this.$_.findIndex(this.excelUploadInfo.columns[this.columnIdx], {label: _examineName, isExamine: true})
            if (idx1 > -1) {
              resultRows.push({
                regUserId: this.$store.getters.user.userId,
                heaCheckupPlanId: this.heaCheckupPlanId,
                examineId: this.excelUploadInfo.columns[this.columnIdx][idx1].name,
                examineName: _examineName,
                examineData: row[_examineName],
              })
            }
          })
          
          saveRows.push({
            regUserId: this.$store.getters.user.userId,
            heaCheckupPlanId: this.heaCheckupPlanId,
            heaCheckupUploadId: seq + '' + this.seqCount++,
            heaCheckupOpinionUploadId: seq + '' + this.seqCount++,
            deptName: row['소속'],
            empNo: row['사번'],
            birthDate: this.formatDate(row['생년월일']),
            checkupDate: this.formatDate(row['검진일자']),
            userName: row['성명'],
            hazardName: row['유해인자'],
            healthType: row['건강구분'],
            checkupOpinion: row['검진소견'],
            opinionDetailResult: row['소견 세부결과'],
            afterManage: row['사후관리소견'],
            suitableFlag: row['업무수행적합여부'],
            remark: row['비고'],
            saveDetails: resultRows,
          })
        })
        this.$http.url = this.tempSaveUrl;
        this.$http.type = 'POST';
        this.$http.isLoading = false;
        this.$http.param = saveRows
        this.$http.request((_result) => {
          if (_result.data === "SUCCESS") {
            this.ingCnt +=  (sliceRows && sliceRows.length > 0 ? sliceRows.length : 0); // 처리건수 카운트
            this.uploadIndex ++; // 다음시트로 넘어감
            resolve(true);
          } else if (_result.data === "FAIL") {
            this.thirdShow = false;
            this.ingCnt +=  (sliceRows && sliceRows.length > 0 ? sliceRows.length : 0);
            this.uploadIndex ++;
            this.deleteTempData();
            this.failMessage = '업로드 결과 저장 중 에러가 발생하였습니다.<br/>담당자에게 문의 바랍니다.'
            _reject("");
          }
        }, () => {
          _reject("");
          this.deleteTempData();
        });
      })
    },
    validCheck() {
      return new Promise((resolve, _reject) => {
        this.$http.url = this.tempListUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          noUserFlag: ''
        }
        this.$http.request((_result) => {
          /**
           * 다시 업로드하는 경우 
           * 1. 검진일자 없을 때
           * 2. 업로드 할 데이터가 없을 때
           * 
           * 유저 정보가 없을 때
           * 1. 확인 : 필터링 -> 유저 제외
           * 2. 취소 : 다시 업로드 
           */
            if (_result.data && _result.data.length > 0) {
              let idx = this.$_.findIndex(_result.data, {checkupDate: '' || null})
              let noUserList = this.$_.filter(_result.data, {noUserFlag: 'Y'})
              // let retireUserList = this.$_.filter(noUserList, {retireFlag: 'Y'})
              
              if (idx > -1) {
                this.thirdShow = false;
                this.deleteTempData();  
                this.failMessage = '검진일자를 입력하지 않은 데이터가 있습니다.<br>입력 후 다시 업로드하세요.'
                _reject("");
              }
              if (noUserList && noUserList.length > 0) {
                this.noUserCnt = noUserList.length;
                // this.retireUserCnt = retireUserList.length;
                this.noUsers = this.$_.map(noUserList, 'userName').toString();
                // this.retireUsers = this.$_.map(retireUserList, 'userName').toString();
                window.getApp.$emit('CONFIRM', {
                  title: '확인',
                  message: '생년월일과 성명이 일치하지 않거나 퇴직한 대상자가 있습니다. <br>제외하고 업로드하시겠습니까?', 
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    this.$_.forEach(noUserList, _item => {
                      this.jsonData = this.$_.reject(this.jsonData, {'생년월일': _item.birthDate, '성명': _item.userName})
                    })
                    resolve(true);
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                    this.thirdShow = false;
                    this.deleteTempData();
                    this.failMessage = '대상자 확인 후 다시 업로드하세요. [ ' + this.noUsers + ' ]' 
                    _reject("");
                  },
                });
              } else {
                resolve(true)
              }
            } else {
              this.thirdShow = false;
              this.deleteTempData();
              this.failMessage = '업로드 할 데이터가 없습니다.'
              _reject("");
            }
          }, () => {
          _reject("");
          this.deleteTempData();
        });
      }); 
    },
    completeUpload() {
      return new Promise((resolve, _reject) => {
        this.firstShow = false;
        this.secondShow = false;
        this.thirdShow = false;
        this.fourthShow = true;
        let rowData = [];
        
        this.$_.forEach(this.jsonData, data => {
          let cellData = {
            error_message: '',
          };
          this.$set(cellData, this.excelUploadInfo.rowKeys[this.columnIdx], uid())
          this.excelUploadInfo.data[this.columnIdx] = [];
          this.$_.forEach(this.excelUploadInfo.columns[this.columnIdx], item => {
            // 날짜 형식 변환
            if (item.name === 'checkupDate' || item.name === 'birthDate') { // 'checkupDate'는 엑셀에서 가져온 날짜 컬럼의 이름입니다.
              cellData[item.name] = this.formatDate(data[item.label]); // 형식 변환 함수 호출
            } else cellData[item.name] = data[item.label]; // 업로드한 엑셀데이터를 한줄씩 오브젝트에 넣는다.
          });
          rowData.push(cellData);
        })
        this.$set(this.excelUploadInfo.data, this.columnIdx, rowData) // 데이터 표시
        this.message += '<b>■ '+this.$language('Sheet 명')+'</b> : ' + this.cfb.SheetNames[this.columnIdx] + '\n'
        + ' <b>- '+this.$language('총 데이터 수')+'</b> : ' + this.excelUploadInfo.data[this.columnIdx].length + '\n'
        + ' <b color="#8BC34A">- '+this.$language('업로드 된 데이터 수')+'</b> : ' + this.jsonData.length + '\n'
        + ' <b color="#F44336">- '+this.$language('제외 된 데이터 수')+'</b> : ' +  this.noUserCnt + ' [' + this.noUsers + ']\n'
        + ' <b>- '+this.$language('퇴직자는 업로드 할 수 없습니다.')+'</b>\n'
        
        // 업데이트된 SheetNames로 sheetNames 값을 업데이트
        let _items = [];
        if (this.cfb.SheetNames && this.cfb.SheetNames.length > 0) {
          let idx = 0;
          this.$_.forEach(this.cfb.SheetNames, sheet => {
            _items.push({
              name: idx, 
              label: sheet
            })
            idx++;
          });
        }
        // 여기서 sheetNames를 업데이트하면 tabItems도 자동으로 변경됩니다.
        this.sheetNames = _items.map(item => item.label);

        setTimeout(() => { // 2초 후 업로드 프로세스 완료
          this.visible = false;
          this.$http.isLoading = true;
          this.emitUpload();
        }, 2000);

        this.$emit('saveFileInfo', this.fileInfo2)

        resolve(true); // Promise 완료
      })
    },  
    // 날짜를 'YYYY-MM-DD' 형식으로 변환하는 함수
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    renameKey(obj, oldKey, newKey) {
      // Key값 바꾸면서 검진항목 중 빈 값들은 필터
      if (oldKey !== newKey && obj[oldKey]) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey]
      }
    },
    emitUpload() {
      this.isUpload = false;
      this.$emit('noUpload', false)
    },
    rejected(info) {
      if (!info || info.length === 0) {
        return;
      }
      // 다시 파일을 올림으로 인해 메시지를 초기화 처리함
      this.isError = false;
      this.message = '';
      this.$_.forEach(info, reject => {
        // accept, max-file-size, max-total-size, filter, etc
        switch(reject.failedPropValidation) {
          case 'max-file-size': // 파일용량 초과
          case 'max-total-size': // 파일 전체 용량 초과
            this.isError = true;
            this.message += this.$language('첨부하신 파일의 용량이 지정된 용량보다 큽니다. 파일 용량 : ') + this.getFileSizeTextByRound(reject.file.size)
            break;
          case 'max-files': // 업로드 갯수 초과
            this.isError = true;
            this.message = this.$language('첨부하신 파일의 지정된 업로드 갯수를 초과하여 업로드 되지 않았습니다.')
            break;
          case 'accept': // 확장자 맞지않음
            this.isError = true;
            this.message = this.$language('첨부하신 파일의 확장자가 올바르지 않습니다.') +' ['+ reject.file.name+']'
            break;
          case 'filter': // filter 걸린경우
            break;
          default:
            break;
        }
      })
    },
    getExcelTemplateHeader() {
      if (!this.excelUploadInfo.taskClassCd) return;
      this.$http.url = this.templateExcelFileUrl;
      let thisVue = this;
      this.$http.type = 'GET';
      this.$http.param = {
        taskClassCd: this.excelUploadInfo.taskClassCd,
      };
      this.$http.request( _result => {
          let data = _result.data
          let cfb = XLSX.read(data, {type: 'base64'});
          this.sheetNames = [];
          this.templateHeader = [];
          let sheetNum = this.excelUploadInfo.sheetNum || 0
          for (let i = 0; i <= sheetNum; i++) {
            this.sheetNames.push(cfb.SheetNames[i])
            this.templateHeader.push(thisVue.$comm.getHeaders(cfb.Sheets[cfb.SheetNames[i]]))
          }
        },
      );
    },
    downTemplate(file) {
      let thisVue = this;
      this.$http.url = this.templateExcelFileUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        taskClassCd: this.excelUploadInfo.taskClassCd,
      };
      this.$http.request( _result => {
          let url = window.URL || window.webkitURL;
          let link = document.createElement('a');
          let blob = thisVue.$comm.base64ToBlob(_result.data, this.fileInfo.fileExt);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, this.fileInfo.oriFileNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.fileInfo.oriFileNm;
            link.click();
          }
        },
      );
    },
    deleteFile() {
      if (this.isUpload) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '업로드 중 파일을 삭제할 수 없습니다.',
          type: 'error', // success / info / warning / error
        });
        return;
      }
      // this.$refs['excel-upload'].removeQueuedFiles();
      this.isError = false;
      this.message = ''
      this.excelUploadInfo.data = [];
      this.deleteTempData();
      this.$refs['excel-upload'].reset();
    },
    deleteTempData() {
      this.$http.url = this.tempDeleteUrl;
      this.$http.type = 'DELETE';
      this.$http.request(
        _result => {},
      );
      this.emitUpload();
    },
    removeData(idx) {
      let selectData = this.$refs['table' + idx].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.$set(this.excelUploadInfo.data, idx, this.$_.reject(this.excelUploadInfo.data[idx], (gridItem) => {
            return gridItem[this.excelUploadInfo.rowKeys[idx]] === item[this.excelUploadInfo.rowKeys[idx]]
          }))
        })
        this.$refs['table' + idx].$refs['compo-table'].clearSelection();
      }
    },
    setTrClass(props) {
      return props.row.error_message ? 'bg-orange-5' : '';
    },
    convertEnter(str) {
      return str ? str.replace(/(?:\r\n|\r|\n)/g, '<br />') : '';
    },
  },
};
</script>
<style lang="sass">
.excel-upload
  .q-btn
    margin-top: 0px !important
    margin-left: 0px !important
.excel-upload.q-uploader--disable
    min-height: 200px !important
.excel-upload-cover
  margin-top: 5px !important
  height: 700px

.q-uploader--disable
  max-height: 36px !important
  min-height: 20px !important

.empty_dscription
  font-size: 14px
  color: #909399
  margin-bottom: 0 !important

.file-origin-name
  line-height: 2em!important

.file-description
  .q-field--with-bottom
    padding-bottom: 0px !important
  .q-field--dense .q-field__inner
    padding-bottom: 0px !important

.excel-upload-status-card
  .customCardbody
    padding-top: 0px !important

.excel-upload-btn
  margin: 0px !important
  margin-right: 3px !important
  height: 26px !important

.txtfileinfo
  padding-left: 0px !important
  word-break: break-all
  font-size: 12px
.nofileArea
  height: 196px
  font-size: 0.95em !important
</style>
<style>
.check-up-spinner {
  color: #3498db;
}
.check-up-complete-icon {
  font-size: 5.5em;
}

.check-up-loading-style {
  background: white; /* #3498db; */
  height: 600px;
}
.check-up-loading-style-text {
  font-size: 2.1em;
  font-weight: 600;
}
.check-up-loading-style-text-sub {
  font-size: 1.1em;
  font-weight: 400;
  cursor: pointer;
}

.check-up-animation {
  color: rgba(0, 0, 0, .3);
}
.check-up-animation:before {
  content: attr(data-text);
  position: absolute;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  color: #3498db;
  animation: loading 3s linear;
  animation-iteration-count: infinite;
}
@keyframes loading {
    0% {
        max-width: 0;
    }
}

.thumbIcon{
  font-size: 5em;
  animation: motion 0.3s /* 속도 */
              linear 0s   /* 처음부터 끝까지 일정 속도로 진행 */
              infinite alternate; /* 무한 반복 */
}
@keyframes motion {
0% {margin-top: 0px;} /* 처음 위치 */
100% {margin-top: 10px;} /* 마지막 위치 */
}
</style>