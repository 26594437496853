import request from "@/utils/request";
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
import common from "@/js/common";

export function sso() {
  return request({
    url: transactionConfig.sso.insert.url,
    method: "post",
  });
}

export function loginByUserAccount(_userId, _userPwd, _userOtpPass) {
  const data = {
    loginId: _userId,
    loginPwd: _userPwd,
    userOtpPass: _userOtpPass,
    deviceType: "P",
  };
  return request({
    url: transactionConfig.auth.login.insert.url,
    method: "post",
    data,
  });
}

export function logout() {
  return request({
    url: transactionConfig.auth.login.logout.url,
    method: "post",
  });
}

export function getUserInfo(token) {
  return request({
    url: selectConfig.auth.userInfo.url,
    method: "get",
    params: {
      token,
      deviceTypeCd: "P",
      lang: common.getLang(),
    },
  });
}

export function updateTokenByRefreshToken() {
  return request({
    url: selectConfig.auth.login.refresh.url,
    method: "get",
  });
}

export function validToken() {
  return request({
    url: selectConfig.auth.login.valid.url,
    method: "get",
  });
}

export function getLangInfo(_lang, _divide) {
  const data = {
    lang: _lang,
    divide: _divide,
  };
  return request({
    url: selectConfig.system.lang.url,
    method: "post",
    data,
  });
}

export function getUserMenus(empNo) {
  var url = selectConfig.sys.userMenu.get.url + empNo;
  return request({
    url: url,
    method: "get",
    params: {
      lang: common.getLang(),
      deviceTypeCd: "P",
    },
  });
}

export function getFavorites(userId) {
  var url = selectConfig.sys.favorite.list.url;
  return request({
    url: url,
    method: "get",
    params: {
      lang: common.getLang(),
      userId: userId,
    },
  });
}

export function getServerUrl() {
  var url = selectConfig.com.mobileHostname.url;
  return request({
    url: url,
    method: "get",
  });
}
